import {
  Box,
  Center,
  Divider,
  Flex,
  Link,
  SlideFade,
  Spinner,
  Stack,
  Text,
  forwardRef,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  postCommentRepliesPath,
  postCommentReplyPath,
  postCommentReplyUsefulPath,
} from "../../../../routes";
import CustomLinkLinkify from "../../shared/components/atoms/CustomLinkLinkify";
import useRequest from "../../shared/lib/useRequest";
import {
  SharedApprovedCurrentUser,
  PostComment,
  PostCommentRepliesIndex,
  PostsShow,
  UserPostCommentReply,
} from "../../shared/lib/types";
import { Button } from "../../shared/components/atoms";
import PostResourceAvatar from "../../shared/components/atoms/PostResourceAvatar";
import PostResourceDisplayName from "../../shared/components/atoms/PostResourceDisplayName";
import ExpandLessIcon from "../../shared/components/icons/ExpandLessIcon";
import TreeCurveIcon from "../../shared/components/icons/TreeCurveIcon";
import TreeJunctionIcon from "../../shared/components/icons/TreeJunctionIcon";
import AnonymousField from "./AnonymousField";
import FilesWithoutImage from "../../shared/components/atoms/FilesWithoutImage";
import ImagesWithSlider from "../../shared/components/atoms/ImagesWithSlider";
import { EditModal, ReplyFormFields, useReplyForm } from "./CommentReplyForm";
import MoreMenu from "../../shared/components/atoms/MoreMenu";
import UsefulButton from "./UsefulButton";
import UserProfileLink from "./UserProfileLink";
import { HelperImageFile } from "../../../shared/lib/types";
import useFlash from "../../shared/lib/useFlash";

const ReplyWrapper = ({
  reply: propReply,
  currentUser,
  post,
}: {
  reply: UserPostCommentReply;
  currentUser: SharedApprovedCurrentUser;
  post: PostsShow;
}) => {
  const [reply, setReply] = useState(propReply);
  return (
    <Reply
      key={reply.updated_at}
      reply={reply}
      currentUser={currentUser}
      onUpdated={setReply}
      post={post}
    />
  );
};

const EditReplyModal = ({
  reply,
  onUpdated,
  editModalDisclosure,
  currentUser,
}: {
  reply: UserPostCommentReply;
  onUpdated: (data: UserPostCommentReply) => void;
  editModalDisclosure: ReturnType<typeof useDisclosure>;
  currentUser: SharedApprovedCurrentUser;
}) => {
  const request = useRequest();
  const showFlash = useFlash();

  const editReplyFormState = useReplyForm({
    onSubmit: async (data) => {
      const res = await request(postCommentReplyPath(reply.code), "PUT", data);
      if (res.ok) {
        onUpdated(await res.json());
      } else if (res.status === 404) {
        showFlash({
          error: (
            <>
              返信が見つかりませんでした。
              <br />
              ページを再読み込みしてください。
            </>
          ),
        });
      }
    },
    defaultValues: { content: reply.content },
    defaultImageFiles: reply.image_files,
    defaultFilesWithoutImage: reply.files_without_image,
  });

  return (
    <EditModal
      isOpen={editModalDisclosure.isOpen}
      onClose={editModalDisclosure.onClose}
      formState={editReplyFormState}
      title="返信編集"
    >
      <ReplyFormFields
        formState={editReplyFormState}
        currentUser={currentUser}
        anonymous={reply.author.anonymous}
      />
    </EditModal>
  );
};

const Reply = ({
  reply,
  currentUser,
  onUpdated,
  post,
}: {
  reply: UserPostCommentReply;
  currentUser: SharedApprovedCurrentUser;
  onUpdated: (reply: UserPostCommentReply) => void;
  post: PostsShow;
}) => {
  const editModalDisclosure = useDisclosure();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current == null) return;

    const search = new URLSearchParams(location.search);
    if (reply.code == search.get("reply_code")) {
      ref.current.scrollIntoView({ block: "start" });
      // ピッタリはみづらいので微調整
      window.scrollTo(0, window.scrollY - 24);
    }
    // 初回のみ実行したいので
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SlideFade in>
      <Flex gap={3} ref={ref}>
        <UserProfileLink
          author={reply.author}
          currentUser={currentUser}
          h="fit-content"
        >
          <PostResourceAvatar
            w={{ base: 8, sm: 12 }}
            h={{ base: 8, sm: 12 }}
            author={reply.author}
          />
        </UserProfileLink>
        <Stack gap={1} w="full">
          <Flex justify="space-between" align="center">
            <UserProfileLink
              author={reply.author}
              currentUser={currentUser}
              fontSize="sm"
            >
              <PostResourceDisplayName author={reply.author} />
            </UserProfileLink>
            {!post.is_resolved && reply.is_own && (
              <MoreMenu
                buttonProps={{
                  "data-testid": "post-comment-reply-detail-button",
                }}
                listItemProps={{
                  as: "button",
                  textAlign: "left",
                  onClick: editModalDisclosure.onOpen,
                }}
                listItemText="返信を編集"
              />
            )}
          </Flex>
          <Box
            px={4}
            py={3}
            bgColor={reply.is_own ? "brand.100" : "#F5F5F5"}
            fontSize="sm"
            borderRadius="8px"
            wordBreak="break-word"
            whiteSpace="pre-wrap"
            w="full"
          >
            <Stack
              columnGap={3}
              rowGap={0}
              direction={{ base: "column", md: "row" }}
            >
              <Box fontSize="xs" color="#848484">
                投稿日：{dayjs(reply.created_at).format("L LT")}
              </Box>
              {reply.updated_at !== "" &&
                reply.created_at !== reply.updated_at && (
                  <Box fontSize="xs" color="#848484">
                    最終更新日：{dayjs(reply.updated_at).format("L LT")}
                  </Box>
                )}
            </Stack>
            <Box mt={2}>
              <CustomLinkLinkify>{reply.content}</CustomLinkLinkify>
            </Box>
            {reply.files_without_image.length !== 0 && (
              <Box mt={2}>
                <FilesWithoutImage files={reply.files_without_image} />
              </Box>
            )}
          </Box>
          {reply.image_files.length !== 0 && (
            <Box mt={2}>
              <ImagesWithSlider
                urls={reply.image_files.map(
                  (file: HelperImageFile) => file.url,
                )}
              />
            </Box>
          )}
          <Box>
            <UsefulButton
              postChild={reply}
              path={postCommentReplyUsefulPath(reply.code)}
              isDisabled={reply.is_own}
            />
          </Box>
        </Stack>
        <EditReplyModal
          reply={reply}
          onUpdated={onUpdated}
          editModalDisclosure={editModalDisclosure}
          currentUser={currentUser}
        />
      </Flex>
    </SlideFade>
  );
};

const NewReplyForm = forwardRef(
  (
    {
      comment,
      fetchReplies,
      currentUser,
      post,
      onSubmit,
    }: {
      comment: PostComment;
      fetchReplies: (comment_code: string) => void;
      currentUser: SharedApprovedCurrentUser;
      post: PostsShow;
      onSubmit?: () => void;
    },
    ref,
  ) => {
    const request = useRequest();
    const showFlash = useFlash();

    const newReplyFormState = useReplyForm({
      onSubmit: async (data) => {
        const res = await request(
          postCommentRepliesPath(comment.code),
          "POST",
          data,
        );
        if (res.ok) {
          if (onSubmit) onSubmit();
          fetchReplies(comment.code);
        } else if (res.status === 404) {
          showFlash({
            error: (
              <>
                コメントが見つかりませんでした。
                <br />
                ページを再読み込みしてください。
              </>
            ),
          });
        }
      },
      hasAnonymousField: currentUser?.is_anonymousable,
      defaultValues: {
        content: "",
        anonymous: post.previous_post_anonymous,
      },
    });

    const anonymous = newReplyFormState.methods.watch("anonymous");

    return (
      <Box
        as="form"
        onSubmit={newReplyFormState.onSubmit}
        autoFocus
        ref={ref}
        flex="1"
        mt={{ base: 1, sm: 3.5 }}
        data-testid="new-reply-form"
      >
        {currentUser?.is_anonymousable && (
          <AnonymousField
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            control={newReplyFormState.methods.control}
            anonymous={anonymous ?? ""}
            currentUser={currentUser}
            showPreview={false}
            label="返信者の表示名"
          />
        )}
        <Box mt={4}>
          <ReplyFormFields
            formState={newReplyFormState}
            currentUser={currentUser}
            anonymous={anonymous === "1"}
          />
        </Box>
        <Button
          w="full"
          mt={{ base: 6, sm: 8 }}
          isDisabled={!newReplyFormState.isSubmittable}
          isLoading={newReplyFormState.isSubmitting}
          type="submit"
        >
          返信
        </Button>
      </Box>
    );
  },
);

const Replies = ({
  comment,
  currentUser,
  onFetched,
  onClose,
  isFocusInput,
  post,
}: {
  comment: PostComment;
  currentUser: SharedApprovedCurrentUser;
  onFetched: (result: PostCommentRepliesIndex) => void;
  onClose: () => void;
  isFocusInput: boolean;
  post: PostsShow;
}) => {
  // TODO: dev storybookのためなのでmockして消したい
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const [replies, setReplies] = useState(comment.replies);

  const request = useRequest();
  const fetchReplies = useCallback(
    async (comment_code: string) => {
      const res = await request(postCommentRepliesPath(comment_code), "GET");
      const result = await res.json();
      setReplies(result.replies);
      onFetched(result);
    },
    [request, onFetched],
  );

  useEffect(() => {
    void fetchReplies(comment.code);
  }, [fetchReplies, comment.code]);

  const ref = useRef<HTMLDivElement>();
  const endOfRepliesRef = useRef<HTMLDivElement>(null);

  // 「返信する」を押した時のみ実行して欲しいが、repliesの変更を追う必要があるので
  // 返信の追加時にも発火してしまう
  // stateで1回実行したかどうかを保持しておく
  const [firstScroll, setFirstScroll] = useState(false);
  useEffect(() => {
    if (!firstScroll && replies && isFocusInput && ref.current) {
      setFirstScroll(true);
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [replies, isFocusInput, firstScroll]);

  return (
    <>
      <Stack mt={2} gap={3}>
        {!replies ? (
          <Box textAlign="center">
            <Spinner />
          </Box>
        ) : (
          [...replies].reverse().map((reply, i) => (
            <Flex gap={3} key={reply.code}>
              <Stack gap={2}>
                {post.is_resolved && i == replies.length - 1 ? (
                  <TreeCurveIcon boxSize={{ base: 7, sm: 12 }} />
                ) : (
                  <>
                    <TreeJunctionIcon boxSize={{ base: 7, sm: 12 }} />
                    <Center h="full">
                      <Divider
                        orientation="vertical"
                        borderWidth="1px"
                        borderColor="brand.100"
                        opacity="1"
                      />
                    </Center>
                  </>
                )}
              </Stack>
              <Box flex="1">
                <ReplyWrapper
                  reply={reply}
                  currentUser={currentUser}
                  post={post}
                />
              </Box>
            </Flex>
          ))
        )}
        <div ref={endOfRepliesRef} />
        {post.is_resolved ? (
          <Text
            align="center"
            fontSize="sm"
            color="#6D787D"
            ml={32}
            wordBreak="keep-all"
            overflowWrap="anywhere"
          >
            解決済みの質問のため、
            <wbr />
            返信できません
          </Text>
        ) : (
          <Flex gap={3}>
            <TreeCurveIcon boxSize={{ base: 7, sm: 12 }} />
            <NewReplyForm
              ref={ref}
              comment={comment}
              fetchReplies={fetchReplies}
              currentUser={currentUser}
              post={post}
              onSubmit={() => {
                endOfRepliesRef.current?.scrollIntoView({ behavior: "smooth" });
              }}
            />
          </Flex>
        )}
        <Flex justify="flex-end">
          <Link
            as="button"
            fontSize="xs"
            onClick={() => {
              onClose();
            }}
          >
            <ExpandLessIcon />
            返信一覧を閉じる
          </Link>
        </Flex>
      </Stack>
    </>
  );
};

export default Replies;
