import {
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Link,
  List,
  ListItem,
  Show,
  Stack,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { Flash } from "../../shared/lib/types";
import { Tag } from "../shared/components/atoms";
import Background from "../shared/components/atoms/Background";
import CustomLinkLinkify from "../shared/components/atoms/CustomLinkLinkify";
import Footer from "../shared/components/atoms/Footer";
import Header from "../shared/components/atoms/Header";
import HelpMessage from "../shared/components/atoms/HelpMessage";
import InfoMessage from "../shared/components/atoms/InfoMessage";
import Overlay from "../shared/components/atoms/Overlay";
import RegisterOrLoginButtons from "../shared/components/atoms/RegisterOrLoginButtons";
import RegistrationRequiredAction from "../shared/components/atoms/RegistrationRequiredAction";
import StudyGroupCaution from "../shared/components/atoms/StudyGroupCaution";
import { CalendarMonthIcon } from "../shared/components/icons";
import Application from "../shared/components/layouts/Application";
import { StudyGroup, SharedCurrentUser } from "../shared/lib/types";
import CommentsWithForm from "./components/Comemnts";
import VideoForCurrentUser from "./components/Video";
import { newUserDatabaseAuthenticationSessionPath } from "../../../routes";

const Page = ({
  study_group,
  current_user,
}: {
  study_group: StudyGroup;
  current_user: SharedCurrentUser;
}) => {
  return (
    <Background color="brand.50">
      <Container maxW="container.lg">
        {!study_group.is_published && (
          <InfoMessage mt={4}>
            非公開の勉強会です。公式アカウントのみが閲覧することができます。
          </InfoMessage>
        )}
        {!study_group.is_started && (
          <InfoMessage mt={4}>
            公開期間開始前の勉強会です。公式アカウントのみが閲覧することができます。
          </InfoMessage>
        )}
        {study_group.is_ended && (
          <InfoMessage mt={4}>
            公開期間終了後の勉強会です。公式アカウントのみが閲覧することができます。
          </InfoMessage>
        )}
      </Container>
      <Container as="main" maxW="container.lg" mt={{ base: 6, sm: 12 }}>
        <Stack gap={0}>
          <Box
            borderRadius={{ base: 8, sm: 16 }}
            overflow="hidden"
            pos="relative"
          >
            <VideoForCurrentUser
              poster={study_group.thumbnail_url}
              src={study_group.video_file_url}
              currentUser={current_user}
            />
            {!current_user?.is_all_public_feature_accessible && (
              <Overlay
                background="rgba(0, 0, 0, 0.8)"
                gap={{ base: 4, md: 6 }}
                px={8}
              >
                <Text
                  color="white"
                  textAlign="center"
                  fontWeight="bold"
                  fontSize={{ base: "sm", md: "2xl" }}
                  wordBreak={"keep-all"}
                  overflowWrap={"anywhere"}
                >
                  {current_user?.is_temporary &&
                  !current_user?.personal_info_applicationing ? (
                    <>
                      本人確認が完了すると、
                      <wbr />
                      勉強会動画の視聴が可能になります。
                    </>
                  ) : current_user?.is_temporary &&
                    current_user.personal_info_applicationing ? (
                    <>
                      本人確認が完了すると、
                      <wbr />
                      勉強会動画の視聴が可能になります。
                      <wbr />
                      本人確認の審査が完了するまでお待ちください。
                    </>
                  ) : (
                    "動画を視聴するには会員登録が必要です。"
                  )}
                </Text>
                {(current_user == null ||
                  (current_user?.is_temporary &&
                    !current_user?.personal_info_applicationing)) && (
                  <RegisterOrLoginButtons
                    currentUser={current_user}
                    loginButtonProps={{
                      color: "gray",
                      display: { base: "flex", md: "none" },
                    }}
                  />
                )}
                {current_user == null && (
                  <Show above="md">
                    <Link
                      color="white"
                      _hover={{ textDecor: "underline" }}
                      href={newUserDatabaseAuthenticationSessionPath()}
                    >
                      すでに会員の方はこちらからログイン
                    </Link>
                  </Show>
                )}
              </Overlay>
            )}
          </Box>
          <Flex mt={3} justifyContent="flex-end">
            <HelpMessage
              as="a"
              href="https://aloop.jp/announcements/20241209_101011"
              target="_blank"
              rel="noreferrer"
            >
              勉強会視聴方法
            </HelpMessage>
          </Flex>
          <Box
            backgroundColor="white"
            pt={{ base: 6, sm: 14 }}
            pb={{ base: 6, md: 12 }}
            borderRadius={8}
            mt={{ base: 4, md: 5 }}
            border="1px solid #DCE5E3"
          >
            <Container maxW="container.md" px={5}>
              <Heading as="h1" size={{ base: "md", sm: "lg" }}>
                {study_group.title}
              </Heading>
              <HStack mt={3} gap={0.5}>
                <CalendarMonthIcon />
                <Text fontSize={{ base: "xs", md: "md" }} pt={0.5}>
                  {study_group.ended_at && study_group.is_ended
                    ? `${dayjs(study_group.ended_at).format(
                        "L(dd) HH:mm",
                      )}公開終了`
                    : study_group.ended_at
                      ? `${dayjs(study_group.ended_at).format(
                          "L(dd) HH:mm",
                        )}まで視聴可能`
                      : "視聴期限無し"}
                </Text>
              </HStack>
              <Divider mt={{ base: 5, sm: 7 }} />
              <Box mt={{ base: 5, sm: 7 }}>
                <List display="flex" gap={1}>
                  {study_group.tags.map((tag) => (
                    <ListItem key={tag.name}>
                      <Tag>{tag.name}</Tag>
                    </ListItem>
                  ))}
                </List>
                <Box
                  whiteSpace="pre-wrap"
                  mt={4}
                  fontSize={{ base: "sm", sm: "md" }}
                >
                  <CustomLinkLinkify>
                    {study_group.description}
                  </CustomLinkLinkify>
                </Box>
              </Box>
              <Divider mt={7} />
              <Box mt={7}>
                <Grid
                  columnGap={{ base: 6, sm: "44px" }}
                  templateColumns="auto 1fr"
                  templateAreas={{
                    base: `
                    "image name"
                    "introduction introduction"
                    "organizer organizer"
                    `,
                    sm: `
                  "image name"
                  "image introduction"
                  "image organizer"
                `,
                  }}
                >
                  <GridItem flexShrink={0} area="image">
                    {study_group.instructor_info.user_url === "" ||
                    study_group.instructor_info.is_canceled ? (
                      <Image
                        w={{ base: 20, sm: 32 }}
                        borderRadius="50%"
                        aspectRatio={1 / 1}
                        src={study_group.instructor_info.instructor_image_url}
                      />
                    ) : (
                      <RegistrationRequiredAction
                        currentUser={current_user}
                        href={study_group.instructor_info.user_url}
                        render={(props) => (
                          <Link {...props} target="_blank" rel="noreferrer">
                            <Image
                              w={{ base: 20, sm: 32 }}
                              borderRadius="50%"
                              aspectRatio={1 / 1}
                              src={
                                study_group.instructor_info.instructor_image_url
                              }
                            />
                          </Link>
                        )}
                      />
                    )}
                  </GridItem>
                  <GridItem area="name" alignSelf="center">
                    <Heading as="div" size={{ base: "xs", sm: "sm" }}>
                      講師
                    </Heading>
                    <Box mt={2}>
                      {study_group.instructor_info.user_url === "" ||
                      study_group.instructor_info.is_canceled ? (
                        <Text overflowWrap="anywhere">
                          {study_group.instructor_info.name}
                        </Text>
                      ) : (
                        <RegistrationRequiredAction
                          currentUser={current_user}
                          href={study_group.instructor_info.user_url}
                          render={(props) => (
                            <Link
                              {...props}
                              target="_blank"
                              rel="noreferrer"
                              overflowWrap="anywhere"
                            >
                              {study_group.instructor_info.name}
                            </Link>
                          )}
                        />
                      )}
                    </Box>
                  </GridItem>
                  <GridItem
                    mt={{ base: 6, sm: 4 }}
                    fontSize="sm"
                    whiteSpace="pre-wrap"
                    wordBreak="break-word"
                    area="introduction"
                  >
                    <CustomLinkLinkify>
                      {study_group.instructor_info.introduction}
                    </CustomLinkLinkify>
                  </GridItem>
                  <GridItem
                    fontSize="sm"
                    mt={{ base: 3, sm: 4 }}
                    color="gray"
                    area="organizer"
                  >
                    主催 ： {study_group.organizer.full_name}
                  </GridItem>
                </Grid>
              </Box>
            </Container>
          </Box>
          {current_user?.is_all_public_feature_accessible &&
            (study_group.comment_enabled ? (
              <CommentsWithForm
                studyGroup={study_group}
                currentUser={current_user}
              />
            ) : (
              <Box textAlign="center" mt={{ base: 4, md: 6 }}>
                <Text color="#656565">
                  この動画はコメントを受け付けていません
                </Text>
              </Box>
            ))}
        </Stack>
        <Box mt={{ base: 6, md: 8 }}>
          <StudyGroupCaution />
        </Box>
      </Container>
    </Background>
  );
};

const StudyGroupsShow = ({
  studyGroup,
  flash,
  currentUser,
}: {
  studyGroup: StudyGroup;
  flash: Flash;
  currentUser: SharedCurrentUser;
}) => {
  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} />
      <Page study_group={studyGroup} current_user={currentUser} />
      <Footer />
    </Application>
  );
};

export default StudyGroupsShow;
