import useFlash from "./useFlash";
import useBaseRequest from "../../../shared/lib/useBaseRequest";
import { useCallback } from "react";

// TODO: dev もうちょっといい感じにエラーを扱いたい
const useRequest = ({ isIgnoreUnexpectedError = false } = {}) => {
  const showFlash = useFlash();
  const onUnexpectedError = useCallback(
    () => {
      if (isIgnoreUnexpectedError) return;

      showFlash({ error: "エラーが発生しました" })
    },
    [isIgnoreUnexpectedError, showFlash],
  );

  const onForbiddenError = useCallback(
    async (message: string) => {
      showFlash({ error: message }, "bottom");
    },
    [showFlash],
  );

  return useBaseRequest({ onUnexpectedError, onForbiddenError });
};

export default useRequest;
