import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const VisibilityOffIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" boxSize={6} {...props}>
    <path
      d="M16.1 13.2998L14.65 11.8498C14.8 11.0665 14.575 10.3331 13.975 9.64981C13.375 8.96647 12.6 8.69981 11.65 8.84981L10.2 7.39981C10.4833 7.26647 10.7708 7.16647 11.0625 7.09981C11.3542 7.03314 11.6667 6.99981 12 6.99981C13.25 6.99981 14.3125 7.43731 15.1875 8.31231C16.0625 9.18731 16.5 10.2498 16.5 11.4998C16.5 11.8331 16.4667 12.1456 16.4 12.4373C16.3333 12.729 16.2333 13.0165 16.1 13.2998ZM19.3 16.4498L17.85 15.0498C18.4833 14.5665 19.0458 14.0373 19.5375 13.4623C20.0292 12.8873 20.45 12.2331 20.8 11.4998C19.9667 9.81647 18.7708 8.47897 17.2125 7.48731C15.6542 6.49564 13.9167 5.9998 12 5.9998C11.5167 5.9998 11.0417 6.03314 10.575 6.0998C10.1083 6.16647 9.65 6.26647 9.2 6.39981L7.65 4.8498C8.33333 4.56647 9.03333 4.35397 9.75 4.2123C10.4667 4.07064 11.2167 3.9998 12 3.9998C14.5167 3.9998 16.7583 4.69564 18.725 6.0873C20.6917 7.47897 22.1167 9.28314 23 11.4998C22.6167 12.4831 22.1125 13.3956 21.4875 14.2373C20.8625 15.079 20.1333 15.8165 19.3 16.4498ZM19.8 22.5998L15.6 18.4498C15.0167 18.6331 14.4292 18.7706 13.8375 18.8623C13.2458 18.954 12.6333 18.9998 12 18.9998C9.48333 18.9998 7.24167 18.304 5.275 16.9123C3.30833 15.5206 1.88333 13.7165 1 11.4998C1.35 10.6165 1.79167 9.79564 2.325 9.03731C2.85833 8.27897 3.46667 7.59981 4.15 6.99981L1.4 4.1998L2.8 2.7998L21.2 21.1998L19.8 22.5998ZM5.55 8.39981C5.06667 8.83314 4.625 9.30814 4.225 9.82481C3.825 10.3415 3.48333 10.8998 3.2 11.4998C4.03333 13.1831 5.22917 14.5206 6.7875 15.5123C8.34583 16.504 10.0833 16.9998 12 16.9998C12.3333 16.9998 12.6583 16.979 12.975 16.9373C13.2917 16.8956 13.6167 16.8498 13.95 16.7998L13.05 15.8498C12.8667 15.8998 12.6917 15.9373 12.525 15.9623C12.3583 15.9873 12.1833 15.9998 12 15.9998C10.75 15.9998 9.6875 15.5623 8.8125 14.6873C7.9375 13.8123 7.5 12.7498 7.5 11.4998C7.5 11.3165 7.5125 11.1415 7.5375 10.9748C7.5625 10.8081 7.6 10.6331 7.65 10.4498L5.55 8.39981Z"
      fill="currentColor"
    />
  </Icon>
);

export default VisibilityOffIcon;
