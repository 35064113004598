import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { SharedCurrentUser } from "../../lib/types";
import RegisterOrLoginButtons from "./RegisterOrLoginButtons";

const RegistrationRequiredModal = ({
  isOpen,
  onClose,
  currentUser,
}: {
  isOpen: boolean;
  onClose: () => void;
  currentUser: SharedCurrentUser;
}) => {
  const isApplicationing =
    currentUser?.is_temporary && currentUser?.personal_info_applicationing;
  const content = !currentUser
    ? {
        title: "ご利用になるには会員登録が必要です",
        body: (
          <>
            会員登録が完了するとすべてのコンテンツが利用可能となります。
            <br />
            会員登録、もしくはログインを行ってください。
          </>
        ),
      }
    : isApplicationing
      ? {
          title: "本人確認の審査中です",
          body: (
            <>
              本人確認が完了するとすべてのコンテンツが利用可能となります。
              <br />
              本人確認の審査が完了するまでお待ちください。
            </>
          ),
        }
      : {
          title: "ご利用になるには本人確認が必要です",
          body: (
            <>
              本人確認が完了するとすべてのコンテンツが利用可能となります。
              <br />
              本人確認を行ってください。
            </>
          ),
        };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent mx={2}>
        <ModalCloseButton />
        <ModalBody
          textAlign="center"
          pt={{ base: 16, md: 28 }}
          pb={{ base: 12, md: 20 }}
        >
          <VStack>
            <Text fontWeight="bold" fontSize={{ md: "2xl" }}>
              {content.title}
            </Text>
            <Text fontSize={{ base: "xs", md: "md" }} mt={{ base: 4, md: 6 }}>
              {content.body}
            </Text>
            {!isApplicationing && (
              <RegisterOrLoginButtons
                currentUser={currentUser}
                mt={{ base: 6, md: 10 }}
                fontSize={{ base: "sm", md: "md" }}
                loginButtonProps={{ variant: "outline" }}
              />
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RegistrationRequiredModal;
