import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Box, Heading, Stack } from "@chakra-ui/react";
import Button from "../../shared/components/atoms/Button";
import { userDatabaseAuthenticationRegistrationPath } from "../../../../routes";
import useRequest from "../../shared/lib/useRequest";
import useFlash from "../../shared/lib/useFlash";
import PasswordInput from "../../shared/components/atoms/PasswordInput";

const PasswordEdit = ({ ...boxProps }) => {
  const schema = yup.object({
    current_password: yup.string().required().label("現在のパスワード"),
    password: yup
      .string()
      .required()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .password()
      .label("新しいパスワード"),
    password_confirmation: yup
      .string()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .confirmation({
        key: "password",
        message: "パスワードの入力が一致しません",
      }),
  });

  type FormData = yup.InferType<typeof schema>;

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
    setError,
  } = useForm<FormData>({
    defaultValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
    resolver: yupResolver(schema),
  });

  const request = useRequest();
  const showFlash = useFlash();

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    const res = await request(
      userDatabaseAuthenticationRegistrationPath(),
      "PUT",
      { user_database_authentication: data },
    );
    if (res.ok) {
      showFlash({ success: "パスワードを変更しました" });
      reset();
    } else {
      const json = await res.json();
      setError("current_password", {
        type: "custom",
        message: json.errors.current_password[0],
      });
    }
  };

  return (
    <Box
      background="#FFF"
      pt={{ base: 6, md: 8 }}
      pb={{ base: 7, md: 9 }}
      px={{ base: 5, md: 8 }}
      borderRadius="2px"
      {...boxProps}
    >
      <Heading as="h1" size={{ base: "md", md: "lg" }}>
        パスワード
      </Heading>
      <Stack gap={{ base: 4, md: 5 }} mt={{ base: 5, md: 8 }}>
        <Controller
          name="current_password"
          control={control}
          render={({ field, fieldState }) => (
            <PasswordInput
              required
              label="現在のパスワード"
              error={fieldState.error?.message ?? ""}
              {...field}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <PasswordInput
              required
              label="新しいパスワード"
              error={fieldState.error?.message ?? ""}
              message="8文字以上で半角英数字を最低1文字ずつ含んでください。"
              {...field}
            />
          )}
        />
        <Controller
          name="password_confirmation"
          control={control}
          render={({ field, fieldState }) => (
            <PasswordInput
              required
              label="新しいパスワード（確認用）"
              error={fieldState.error?.message ?? ""}
              {...field}
            />
          )}
        />
      </Stack>
      <Button
        type="submit"
        isDisabled={!isDirty}
        isLoading={isSubmitting}
        onClick={handleSubmit(onSubmit)}
        mt={{ base: 5, md: 7 }}
        data-testid="submit-change-password-button"
      >
        変更する
      </Button>
    </Box>
  );
};

export default PasswordEdit;
