import {
  Avatar,
  AvatarBadge,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  forwardRef,
  HStack,
  Link,
  Stack,
  StackProps,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useRef } from "react";
import {
  businessMatchingRootPath,
  newContactPath,
  newPostPath,
  newProfilePersonalInfoApplicationPath,
  newUserDatabaseAuthenticationRegistrationPath,
  newUserPersonalInfoApplicationPath,
  newUserSessionPath,
  postsPath,
  studyGroupsPath,
} from "../../../../../routes";
import SmsIcon from "../icons/SmsIcon";
import Button from "./Button";
import { SharedCurrentUser } from "../../lib/types";
import { FlipperContext } from "../../../../shared/lib/FlipperContext";
import DehazeIcon from "../icons/DehazeIcon";
import CircleErrorIcon from "../icons/CircleErrorIcon";
import UserAvatar from "./UserAvatar";

const DrawerLoginButton = () => {
  return (
    <>
      <Box px={5}>
        <Button
          size="sm"
          w="full"
          mt={4}
          as={Link}
          textDecoration="none"
          href={newUserSessionPath()}
        >
          ログイン
        </Button>
      </Box>
      <Box mt={4} px={5} py={4} backgroundColor="#EDF5F3">
        <Button
          size="sm"
          w="full"
          as={Link}
          textDecoration="none"
          variant="outline"
          href={newUserDatabaseAuthenticationRegistrationPath()}
        >
          会員登録（無料）
        </Button>
      </Box>
    </>
  );
};

const DrawerLogoutButton = ({ logout }: { logout: () => void }) => {
  return (
    <>
      <Box as="hr" />
      <Box mt={2} px={5}>
        <DrawerListItem
          as={Link}
          href={newContactPath()}
          target="_blank"
          rel="noreferrer"
        >
          お問い合わせ
        </DrawerListItem>
        <DrawerListItem as="button" onClick={logout}>
          ログアウト
        </DrawerListItem>
      </Box>
    </>
  );
};

const DrawerListItem = forwardRef<StackProps, "div">(
  ({ children, ...props }, ref) => {
    return (
      <HStack
        h={10}
        textColor="inherit"
        textDecoration="none"
        {...props}
        ref={ref}
      >
        <Text fontSize="sm">{children}</Text>
      </HStack>
    );
  },
);

const DrawerProfileMenu = ({
  currentUser,
}: {
  currentUser: NonNullable<SharedCurrentUser>;
}) => {
  return (
    <>
      <Box px={5}>
        <Text fontSize="sm" fontWeight="bold">
          プロフィール
        </Text>
        <Box mt={2}>
          {currentUser.header_profile_menu_items.map(
            ({ label, href }, index) => (
              <DrawerListItem as={Link} href={href} key={index}>
                {label}
              </DrawerListItem>
            ),
          )}
        </Box>
      </Box>
      <Box as="hr" mt={2} />
    </>
  );
};

const DrawerRegistrationStatus = ({
  currentUser,
}: {
  currentUser: NonNullable<SharedCurrentUser>;
}) => {
  return (
    <Box backgroundColor="#EDF5F3" px={5} py={4}>
      <Text fontSize="xs">
        本人確認が完了するとすべてのコンテンツが利用可能になります。
      </Text>
      {!currentUser.personal_info_applicationing ? (
        <Box fontSize="sm" mt={4} color="#CD2929">
          本人確認の状況：
          <Box as="span" fontWeight="bold">
            {currentUser.personal_info_applicationing ? "審査中" : "未申請"}
          </Box>
        </Box>
      ) : (
        <Box fontSize="sm" mt={4}>
          本人確認の状況：
          <Box as="span" fontWeight="bold">
            {currentUser.personal_info_applicationing ? "審査中" : "未申請"}
          </Box>
        </Box>
      )}
      <Button
        mt={2}
        w="full"
        size="sm"
        as={Link}
        textDecoration="none"
        href={
          currentUser.personal_info_applicationing
            ? newProfilePersonalInfoApplicationPath()
            : newUserPersonalInfoApplicationPath()
        }
      >
        {currentUser.personal_info_applicationing
          ? "本人確認の状況を確認する"
          : "本人確認を申請する"}
      </Button>
    </Box>
  );
};

const DrawerProfile = ({
  currentUser,
}: {
  currentUser: NonNullable<SharedCurrentUser>;
}) => {
  if (currentUser?.is_all_public_feature_accessible) {
    return (
      <>
        <UserAvatar
          src={currentUser.profile_image_url}
          bgColor={currentUser.avatar_bgcolor}
          name={currentUser.full_name}
          boxSize={9}
          sx={{ "--avatar-font-size": "1rem" }}
        />
        <Box as="span" fontSize="md">
          {currentUser.full_name}
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Avatar boxSize={9}>
          {!currentUser.personal_info_applicationing && (
            <AvatarBadge boxSize="1em" bg="white">
              <Box>
                <CircleErrorIcon />
              </Box>
            </AvatarBadge>
          )}
        </Avatar>
        <Box>
          <Box fontSize="sm">{currentUser.email}</Box>
        </Box>
      </>
    );
  }
};

const HeaderDrawerWithButton = ({
  currentUser,
  logout,
}: {
  currentUser: SharedCurrentUser | undefined;
  logout: () => void;
}) => {
  const flipper = useContext(FlipperContext);
  const btnRef = useRef<HTMLButtonElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Stack
        as="button"
        gap={0}
        align="center"
        ref={btnRef}
        onClick={onOpen}
      >
        <Flex
          boxSize="28px"
          align="center"
          justify="center"
          position="relative"
        >
          <DehazeIcon />
          {currentUser?.is_temporary && !currentUser?.personal_info_applicationing && (
            <Box
              position="absolute"
              bottom={0}
              right={0}
              transform="translate(25%, 25%)"
              p={0}
              borderRadius={"full"}
              border="0.2em solid white"
              w="1em"
              h="1em"
              bg="white"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box fontSize="sm">
                <CircleErrorIcon />
              </Box>
            </Box>
          )}
        </Flex>
        <Box fontSize="xs">メニュー</Box>
      </Stack>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="xs"
      >
        <DrawerOverlay />
        <DrawerContent maxW="80%">
          <DrawerCloseButton />
          <DrawerHeader pt={6} pb={0} fontWeight="normal">
            <HStack mr={5} wordBreak="break-word">
              {currentUser && <DrawerProfile currentUser={currentUser} />}
            </HStack>
          </DrawerHeader>

          <DrawerBody py={0} px={0}>
            {currentUser?.is_temporary && (
              <Box mt={6}>
                <DrawerRegistrationStatus currentUser={currentUser} />
              </Box>
            )}
            {currentUser && (
              <Box mt={6}>
                <DrawerProfileMenu currentUser={currentUser} />
              </Box>
            )}
            <Box px={5} mt={5}>
              <Text fontSize="sm" fontWeight="bold">
                コンテンツ
              </Text>
              <Box mt={2}>
                <DrawerListItem as={Link} href={postsPath()}>
                  Q&A
                </DrawerListItem>
                {currentUser?.is_all_public_feature_accessible && (
                  <DrawerListItem
                    as={Link}
                    href={postsPath({ only_bookmarked: 1 })}
                  >
                    ブックマーク
                  </DrawerListItem>
                )}
                <DrawerListItem
                  as={Link}
                  href={studyGroupsPath()}
                  onClick={onClose}
                >
                  勉強会
                </DrawerListItem>
                {flipper.business_matching && (
                  <DrawerListItem
                    as={Link}
                    href={businessMatchingRootPath()}
                    onClick={onClose}
                  >
                    受発注 (β版)
                  </DrawerListItem>
                )}
              </Box>
            </Box>
            {currentUser?.is_all_public_feature_accessible && (
              <VStack bgColor={"#EDF5F3"} px={5} py={4} mb={-2}>
                <Box fontSize="xs">Q&Aで気軽に質問・コメントしてみましょう</Box>
                <Button
                  w="full"
                  size="sm"
                  leftIcon={<SmsIcon />}
                  iconSpacing={1}
                  as="a"
                  href={newPostPath()}
                >
                  質問を新規作成
                </Button>
              </VStack>
            )}
            {currentUser && (
              <Box mt={2}>
                <DrawerLogoutButton logout={logout} />
              </Box>
            )}
            {!currentUser && (
              <Box mt={5}>
                <DrawerLoginButton />
              </Box>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default HeaderDrawerWithButton;
