import {
  Stack,
  Input as ChakraInput,
  Text,
  forwardRef,
  InputGroup,
} from "@chakra-ui/react";
import React, { ReactNode, useId } from "react";
import FormLabel from "./FormLabel";
import InputError from "./InputError";
import { InputProps as ChakraInputProps } from "@chakra-ui/react";

export type InputProps = {
  label?: string;
  message?: ReactNode;
  required?: boolean;
  error?: string;
  withLockIcon?: boolean;
  rightElement?: () => ReactNode;
} & ChakraInputProps;

const Input = forwardRef<InputProps, "input">(
  (
    {
      label = "",
      message = "",
      required = false,
      error = "",
      type = "text",
      id = "",
      placeholder = "",
      withLockIcon = false,
      rightElement,
      maxW = undefined,
      w = undefined,
      ...props
    },
    ref,
  ) => {
    const randomId = useId();
    const htmlForId = id === "" ? randomId : id;
    return (
      <Stack width="100%" gap={0.5}>
        {label !== "" && (
          <FormLabel
            required={required}
            htmlFor={htmlForId}
            withLockIcon={withLockIcon}
          >
            {label}
          </FormLabel>
        )}
        <InputGroup maxW={maxW} w={w}>
          <ChakraInput
            placeholder={placeholder}
            _placeholder={{ opacity: 1, color: "#99A9B0" }}
            type={type}
            border="1px solid #99A9B0"
            borderRadius="2px"
            backgroundColor="#ffffff"
            fontSize="md"
            id={htmlForId}
            isInvalid={error !== ""}
            _invalid={{ backgroundColor: "#FAE8E8", borderColor: "#CD4429" }}
            _disabled={{
              color: "#7E8C91",
              backgroundColor: "#F1F1F1",
              opacity: 1,
            }}
            {...props}
            ref={ref}
          />
          {rightElement && rightElement()}
        </InputGroup>
        {message !== "" && (
          <Text color="#6D787D" fontSize="xs">
            {message}
          </Text>
        )}
        {error !== "" && <InputError>{error}</InputError>}
      </Stack>
    );
  },
);

export default Input;
