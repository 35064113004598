import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import useRequest from "../../shared/lib/useRequest";
import useFlash from "../../shared/lib/useFlash";
import { userDatabaseAuthenticationRegistrationPath } from "../../../../routes";
import React from "react";
import { FormLabel, Input } from "../../shared/components/atoms/form";
import Button from "../../shared/components/atoms/Button";
import PasswordInput from "../../shared/components/atoms/PasswordInput";

const EmailEdit = ({ currentEmail }: { currentEmail: string }) => {
  const schema = yup.object({
    email: yup.string().trim().email().required().label("メールアドレス"),
    current_password: yup.string().required().label("パスワード"),
  });

  type FormData = yup.InferType<typeof schema>;

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    setError,
    trigger,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      email: "",
      current_password: "",
    },
    resolver: yupResolver(schema),
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const request = useRequest();
  const showFlash = useFlash();

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    const res = await request(
      userDatabaseAuthenticationRegistrationPath(),
      "PUT",
      {
        user_database_authentication: data,
      },
    );
    if (res.ok) {
      showFlash({ success: "新しいメールアドレスに確認メールを送信しました" });
      onClose();
      reset();
    } else {
      const json = await res.json();
      if (json.errors.email) {
        showFlash({ error: json.errors.email[0] });
      }
      if (json.errors.current_password) {
        setError("current_password", {
          type: "custom",
          message: json.errors.current_password[0],
        });
      }
    }
  };

  const onClick = async () => {
    const isEmailValid = await trigger("email");
    if (isEmailValid) {
      onOpen();
    }
  };

  return (
    <>
      <Box
        background="#FFF"
        pt={{ base: 6, md: 8 }}
        pb={{ base: 7, md: 9 }}
        px={{ base: 5, md: 8 }}
        borderRadius="2px"
      >
        <Heading as="h1" size={{ base: "md", md: "lg" }}>
          メールアドレス
        </Heading>
        <Stack gap={{ base: 4, md: 5 }} mt={{ base: 5, md: 8 }}>
          <Stack gap={1}>
            <FormLabel as="p" mb={0}>
              現在のメールアドレス
            </FormLabel>
            <Text>{currentEmail}</Text>
          </Stack>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                required
                label="新しいメールアドレス"
                error={fieldState.error?.message ?? ""}
                {...field}
              />
            )}
          />
        </Stack>
        <Button
          isDisabled={watch("email") === ""}
          onClick={onClick}
          mt={{ base: 5, md: 7 }}
          data-testid="open-change-email-modal-button"
        >
          変更する
        </Button>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent mx={2}>
          <ModalCloseButton />
          <ModalBody pt={16} pb={16}>
            <Stack gap={4}>
              <Controller
                name="current_password"
                control={control}
                render={({ field, fieldState }) => (
                  <PasswordInput
                    required
                    label="確認のためにパスワードを入力"
                    error={fieldState.error?.message ?? ""}
                    {...field}
                  />
                )}
              />
              <Flex gap={4} justifyContent="flex-end">
                <Button color="gray" onClick={onClose}>
                  キャンセル
                </Button>
                <Button
                  type="submit"
                  isDisabled={watch("current_password") === ""}
                  isLoading={isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                  data-testid="submit-change-email-button"
                >
                  変更する
                </Button>
              </Flex>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmailEdit;
