import {
  Box,
  CheckboxGroup,
  Divider,
  Flex,
  HStack,
  Heading,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { ReactNode } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { profileEmailNotificationSettingPath } from "../../../../routes";
import { Flash, SharedTag } from "../../../shared/lib/types";
import { Button } from "../../shared/components/atoms";
import SiteSeal from "../../shared/components/atoms/SiteSeal";
import {
  FormLabel,
  InputError,
  Radio,
} from "../../shared/components/atoms/form";
import Checkbox from "../../shared/components/atoms/form/Checkbox";
import {
  ProfileEmailNotificationSettingsUserEmailNotificationSetting,
  SharedCurrentUser,
} from "../../shared/lib/types";
import useRequest from "../../shared/lib/useRequest";
import ProfilePageLayout from "../components/ProfilePageLayout";

const ProfileEmailNotificationSettingsEdit = ({
  flash,
  userEmailNotificationSetting,
  fromManagementOptions,
  postChildOptions,
  tags,
  currentUser,
}: {
  flash: Flash;
  userEmailNotificationSetting: ProfileEmailNotificationSettingsUserEmailNotificationSetting;
  fromManagementOptions: { [s: string]: string };
  postChildOptions: { [s: string]: string };
  tags: SharedTag[];
  currentUser: NonNullable<SharedCurrentUser>;
}) => {
  const schema = yup.object().shape({
    from_management: yup
      .string()
      .required()
      .oneOf(Object.keys(fromManagementOptions)),
    post_comment: yup.string().required().oneOf(Object.keys(postChildOptions)),
    post_comment_reply: yup
      .string()
      .required()
      .oneOf(Object.keys(postChildOptions)),
    q_and_a_recommend_notification_tag_ids: yup
      .array(yup.string().required())
      .required()
      .label("Q&Aのメール通知を受信するタグ"),
  });

  const {
    control,
    handleSubmit,
    trigger,
    formState: { isSubmitting, isValid },
  } = useForm({
    defaultValues: userEmailNotificationSetting,
    resolver: yupResolver(schema),
  });

  const request = useRequest();

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    const res = await request(profileEmailNotificationSettingPath(), "PUT", {
      user_email_notification_setting: data,
    });

    if (res.ok) {
      location.reload();
    }
  };

  return (
    <ProfilePageLayout current_user={currentUser} flash={flash}>
      <Box
        background="#FFF"
        px={{ base: 5, md: 8 }}
        py={{ base: 6, md: 10 }}
        borderRadius="2px"
      >
        <Heading as="h1" size={{ base: "md", md: "lg" }}>
          メール受信設定
        </Heading>
        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          mt={{ base: 5, md: 8 }}
        >
          <Stack gap={{ base: 8, md: 7 }}>
            <Box px={1}>
              <FormLabelWithMemo memo="A-Loop 運営事務局からのご案内をメールでお知らせします。">
                A-Loopからのお知らせ
              </FormLabelWithMemo>
              <Controller
                control={control}
                name="from_management"
                render={({ field: { ref, ...field } }) => (
                  <RadioGroup {...field}>
                    <HStack gap={6} mt={4} ml={{ base: 3, md: 6 }}>
                      {Object.keys(fromManagementOptions).map((key) => (
                        <Radio key={key} value={key} ref={ref}>
                          {fromManagementOptions[key]}
                        </Radio>
                      ))}
                    </HStack>
                  </RadioGroup>
                )}
              />
            </Box>
            <Divider borderColor="brand.200" borderWidth={1} />
            <Box px={1}>
              <FormLabelWithMemo>Q&Aの受信通知</FormLabelWithMemo>
              <Stack gap={6} mt={6} ml={{ base: 3, md: 6 }}>
                <Box>
                  <FormLabel fontSize="md">
                    投稿へ回答コメントが届いた場合のお知らせ
                  </FormLabel>
                  <Controller
                    control={control}
                    name="post_comment"
                    render={({ field: { ref, ...field } }) => (
                      <RadioGroup {...field}>
                        <HStack gap={6} mt={3}>
                          {Object.keys(postChildOptions).map((key) => (
                            <Radio key={key} value={key} ref={ref}>
                              {postChildOptions[key]}
                            </Radio>
                          ))}
                        </HStack>
                      </RadioGroup>
                    )}
                  />
                </Box>
                <Box>
                  <FormLabel fontSize="md">
                    回答コメントへ返信が届いた場合のお知らせ
                  </FormLabel>
                  <Controller
                    control={control}
                    name="post_comment_reply"
                    render={({ field: { ref, ...field } }) => (
                      <RadioGroup {...field}>
                        <HStack gap={6} mt={3}>
                          {Object.keys(postChildOptions).map((key) => (
                            <Radio key={key} value={key} ref={ref}>
                              {postChildOptions[key]}
                            </Radio>
                          ))}
                        </HStack>
                      </RadioGroup>
                    )}
                  />
                </Box>
              </Stack>
            </Box>
            <Divider borderColor="brand.200" borderWidth={1} />
            <Box px={1}>
              <FormLabelWithMemo memo="チェックをつけたタグが含まれるQ&Aの新着情報をメールでお知らせします。">
                Q&Aのメール通知
              </FormLabelWithMemo>
              <Controller
                name="q_and_a_recommend_notification_tag_ids"
                control={control}
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <Box mt={4} ml={{ base: 3, md: 6 }}>
                    <Flex columnGap={4} rowGap={3} wrap="wrap">
                      <CheckboxGroup
                        {...field}
                        onChange={async (e) => {
                          field.onChange(e);
                          await trigger(
                            "q_and_a_recommend_notification_tag_ids",
                          );
                        }}
                      >
                        {tags.map(({ id, name }) => (
                          <Checkbox key={id} value={id.toString()} ref={ref}>
                            {name}
                          </Checkbox>
                        ))}
                      </CheckboxGroup>
                    </Flex>
                    {error && <InputError>{error.message}</InputError>}
                  </Box>
                )}
              />
            </Box>
            <Divider borderColor="brand.200" borderWidth={1} />
            <Box>
              <Button
                type="submit"
                isLoading={isSubmitting}
                isDisabled={!isValid}
              >
                変更する
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box mt={6}>
        <SiteSeal />
      </Box>
    </ProfilePageLayout>
  );
};

const FormLabelWithMemo = ({
  children,
  memo = "",
}: {
  children: ReactNode;
  memo?: string;
}) => {
  return (
    <Box>
      <FormLabel fontSize="md">{children}</FormLabel>
      {memo !== "" && (
        <Text fontSize="xs" color="gray" mt={1}>
          {memo}
        </Text>
      )}
    </Box>
  );
};

export default ProfileEmailNotificationSettingsEdit;
