import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  newUserDatabaseAuthenticationPasswordPath,
  userDatabaseAuthenticationPasswordPath,
} from "../../../../routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Heading, Stack } from "@chakra-ui/react";
import ShadowCard from "../../shared/components/atoms/ShadowCard";
import { Button } from "../../shared/components/atoms";
import Application from "../../shared/components/layouts/Application";
import useRequest from "../../shared/lib/useRequest";
import FixedBackgroundBuildingImage from "../../shared/components/atoms/FixedBackgroundBuildingImage";
import Background from "../../shared/components/atoms/Background";
import Header from "../../shared/components/atoms/Header";
import { Flash } from "../../../shared/lib/types";
import PasswordInput from "../../shared/components/atoms/PasswordInput";

const AuthPasswordsEdit = ({
  resetPasswordToken,
  flash,
}: {
  resetPasswordToken: string;
  flash: Flash;
}) => {
  const schema = yup.object({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    password: yup.string().required().password().label("パスワード"),
    password_confirmation: yup
      .string()
      .oneOf(
        [yup.ref("password"), undefined],
        "パスワードの入力が一致しません",
      ),
  });

  type FormData = yup.InferType<typeof schema>;

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<FormData>({
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
    resolver: yupResolver(schema),
  });

  const request = useRequest();
  const onSubmit = async (data: FormData) => {
    const res = await request(userDatabaseAuthenticationPasswordPath(), "PUT", {
      user_database_authentication: {
        ...data,
        reset_password_token: resetPasswordToken,
      },
    });

    if (res.ok) {
      location.href = res.headers.get("Location")!;
      return;
    } else if (400 <= res.status && res.status <= 499) {
      location.href = newUserDatabaseAuthenticationPasswordPath();
    }
  };

  return (
    <Application flash={flash} disableFloatingRegisterButton>
      <FixedBackgroundBuildingImage />
      <Background>
        <Header onlyLogo />
        <Container
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          maxW="lg"
          mt={{ base: 6, lg: 12 }}
          zIndex={1}
        >
          <ShadowCard>
            <Heading size="md" textAlign="center">
              パスワード再設定
            </Heading>
            <Stack gap={4} mt={{ base: 2, lg: 4 }}>
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState }) => (
                  <PasswordInput
                    label="新しいパスワード"
                    id="password"
                    required
                    error={fieldState.error?.message ?? ""}
                    message="8文字以上で半角英数字を最低1文字ずつ含んでください。"
                    {...field}
                  />
                )}
              />
              <Controller
                name="password_confirmation"
                control={control}
                render={({ field, fieldState }) => (
                  <PasswordInput
                    label="新しいパスワード（確認用）"
                    id="password_confirmation"
                    required
                    error={fieldState.error?.message ?? ""}
                    {...field}
                  />
                )}
              />
            </Stack>
            <Stack mt={4} align="stretch">
              <Button type="submit" isLoading={isSubmitting}>
                パスワード再設定
              </Button>
            </Stack>
          </ShadowCard>
        </Container>
      </Background>
    </Application>
  );
};

export default AuthPasswordsEdit;
