import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  HStack,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Show,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import {
  businessMatchingOwnedBusinessEntityTalkRoomsPath,
  businessMatchingRootPath,
  destroyUserDatabaseAuthenticationSessionPath,
  newContactPath,
  newPostPath,
  newProfilePersonalInfoApplicationPath,
  newUserDatabaseAuthenticationRegistrationPath,
  newUserPersonalInfoApplicationPath,
  newUserSessionPath,
  postsPath,
  rootPath,
  studyGroupsPath,
} from "../../../../../routes";
import { FlipperContext } from "../../../../shared/lib/FlipperContext";
import { LogoSVG } from "../../../svg";
import { SharedCurrentUser, SharedTemporaryCurrentUser } from "../../lib/types";
import useRequest from "../../lib/useRequest";
import HelpIcon from "../icons/HelpIcon";
import SmsIcon from "../icons/SmsIcon";
import Button from "./Button";
import HeaderDrawerWithButton from "./HeaderDrawer";
import CircleErrorIcon from "../icons/CircleErrorIcon";
import UserAvatar from "./UserAvatar";

const hoverStyle = { backgroundColor: "#EDF5F3" };

const GlobalHeader = ({
  currentUser,
  shadow,
}: {
  currentUser?: SharedCurrentUser;
  shadow: boolean;
}) => {
  const flipper = useContext(FlipperContext);
  const request = useRequest();

  const logout = async () => {
    const res = await request(
      destroyUserDatabaseAuthenticationSessionPath(),
      "DELETE",
    );

    if (res.ok) {
      location.href = "/";
    }
  };

  return (
    <>
      {currentUser?.is_readonly_user && (
        <Flex
          py={3}
          bgColor="#B82100"
          color="white"
          justify="center"
          alignItems="center"
          gap={1}
          wrap="wrap"
        >
          <Box fontSize={{ base: "md", md: "lg" }} fontWeight="bold">
            閲覧専用ユーザーでログイン中
          </Box>
          <Box fontSize={{ base: "xs", md: "md" }}>
            （投稿などのアクションは行えません）
          </Box>
        </Flex>
      )}
      <Flex
        bg="white"
        boxShadow={
          shadow ? "0px 8px 16px -8px rgba(10, 100, 79, 0.12)" : undefined
        }
        zIndex={2}
        pl={{ base: "16px", md: 6 }}
        justify="space-between"
        h={{ base: "62px", md: 14 }}
        position="sticky"
        top={0}
      >
        <HStack>
          <HStack as={Link} pb={1} href={rootPath()}>
            <Image src={LogoSVG} h={8} />
          </HStack>
          <Show above="lg">
            <Box ml={6} h="full">
              <ServiceLinks />
            </Box>
          </Show>
        </HStack>
        <Stack>
          <HStack h="full" gap={0}>
            {flipper.business_matching && (
              <MessagesButton currentUser={currentUser} />
            )}
            <Show above="lg">
              {currentUser ? (
                <Flex
                  h="full"
                  flexDirection={
                    // filpper.business_matching削除時はFlexごと削除する
                    flipper.business_matching ? "row" : "row-reverse"
                  }
                >
                  <Menu autoSelect={false}>
                    <Profile currentUser={currentUser} />
                    <ProfileMenuList
                      currentUser={currentUser}
                      logout={logout}
                    />
                  </Menu>
                  {currentUser.is_all_public_feature_accessible && (
                    <Button
                      size="sm"
                      leftIcon={<SmsIcon />}
                      iconSpacing={1}
                      as="a"
                      href={newPostPath()}
                      h="full"
                      borderRadius={0}
                    >
                      質問を投稿
                    </Button>
                  )}
                </Flex>
              ) : (
                <HStack h="full" mr={6} gap={3}>
                  <Button
                    as={Link}
                    textDecoration="none"
                    href={newUserDatabaseAuthenticationRegistrationPath()}
                    variant="outline"
                    size="sm"
                  >
                    会員登録（無料）
                  </Button>
                  <Button
                    as={Link}
                    textDecoration="none"
                    href={newUserSessionPath()}
                    size="sm"
                  >
                    ログイン
                  </Button>
                </HStack>
              )}
            </Show>
            <Show below="lg">
              <Flex h="full" alignItems="center" mr={4}>
                <HeaderDrawerWithButton
                  currentUser={currentUser}
                  logout={logout}
                />
              </Flex>
            </Show>
          </HStack>
        </Stack>
      </Flex>
      {currentUser?.is_temporary && !currentUser.personal_info_applicationing && (
        <Flex
          bgColor="#FFF5F5"
          color="#CD2929"
          fontWeight={"bold"}
          justifyContent={"center"}
          py={3}
          fontSize={{ base: "sm", md: "md" }}
        >
          <Box
            as="a"
            href={newUserPersonalInfoApplicationPath()}
            _hover={{ textDecor: "underline" }}
            target="_blank"
            rel="noreferrer"
          >
            ご利用になるには こちらから 本人確認が必要です
          </Box>
        </Flex>
      )}
    </>
  );
};

const RegistrationStatus = ({
  currentUser,
}: {
  currentUser: NonNullable<SharedTemporaryCurrentUser>;
}) => {
  return (
    <Box backgroundColor="#EDF5F3" px={5} py={4}>
      <HelpIcon color="#80ABA1" />
      <Text fontSize="xs" mt={1}>
        本人確認が完了するとすべてのコンテンツが利用可能になります。
      </Text>
      <Text
        fontSize="sm"
        mt={4}
        color={!currentUser.personal_info_applicationing ? "#CD2929" : ""}
      >
        本人確認の状況：
        <Text as="span" fontWeight="bold">
          {currentUser.personal_info_applicationing ? "審査中" : "未申請"}
        </Text>
      </Text>
      <Button
        as={Link}
        textDecoration="none"
        w="full"
        mt={2}
        size="sm"
        href={
          currentUser.personal_info_applicationing
            ? newProfilePersonalInfoApplicationPath()
            : newUserPersonalInfoApplicationPath()
        }
      >
        {currentUser.personal_info_applicationing
          ? "本人確認の状況を確認する"
          : "本人確認を申請する"}
      </Button>
    </Box>
  );
};

const Profile = ({
  currentUser,
}: {
  currentUser: NonNullable<SharedCurrentUser>;
}) => {
  const Wrapper = ({ children }: { children: ReactNode }) => {
    return (
      <MenuButton h="full" display="flex" _hover={hoverStyle} px={4}>
        <HStack gap={2}>{children}</HStack>
      </MenuButton>
    );
  };
  if (currentUser.is_all_public_feature_accessible) {
    return (
      <Wrapper>
        <UserAvatar
          src={currentUser.profile_image_url}
          bgColor={currentUser.avatar_bgcolor}
          name={currentUser.full_name}
          boxSize={8}
          sx={{ "--avatar-font-size": "1rem" }}
        />
        <Box as="span" fontSize="sm">
          {currentUser.full_name}
        </Box>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        {!currentUser.personal_info_applicationing ? (
          <>
            <Avatar boxSize={8}>
              <AvatarBadge boxSize="1em" bg="white">
                <Box>
                  <CircleErrorIcon />
                </Box>
              </AvatarBadge>
            </Avatar>
            <Box textAlign="left">
              <Box fontSize="sm">{currentUser.email}</Box>
              <Box fontSize="xs" color="#CD2929" fontWeight="bold">
                本人確認が未完了です
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Avatar boxSize={8} />
            <Box textAlign="left">
              <Box fontSize="sm">{currentUser.email}</Box>
              <Box fontSize="xs" color="#6B767A">
                本人確認が未完了です
                <HelpIcon boxSize={3} color="#80ABA1" />
              </Box>
            </Box>
          </>
        )}
      </Wrapper>
    );
  }
};

const CustomMenuItem = ({
  href = undefined,
  target = undefined,
  onClick = undefined,
  rel = undefined,
  children,
}: {
  href?: string;
  target?: string;
  rel?: string;
  onClick?: () => void;
  children: ReactNode;
}) => (
  <MenuItem
    fontSize="sm"
    px={5}
    as={Link}
    href={href}
    onClick={onClick}
    target={target}
    rel={rel}
    _hover={hoverStyle}
  >
    {children}
  </MenuItem>
);

const ProfileMenuList = ({
  currentUser,
  logout,
}: {
  currentUser: NonNullable<SharedCurrentUser>;
  logout: () => void;
}) => {
  const flipper = useContext(FlipperContext);

  return (
    <MenuList pt={0} maxW="xs">
      {currentUser.is_temporary && (
        <RegistrationStatus currentUser={currentUser} />
      )}
      <MenuGroup title="プロフィール" mt={5}>
        {currentUser.header_profile_menu_items.map(({ label, href }, index) => (
          <CustomMenuItem href={href} key={index}>
            {label}
          </CustomMenuItem>
        ))}
      </MenuGroup>
      <MenuDivider />
      <MenuGroup title="コンテンツ" mt={5}>
        <CustomMenuItem href={postsPath()}>Q&A</CustomMenuItem>
        {currentUser.is_all_public_feature_accessible && (
          <CustomMenuItem href={postsPath({ only_bookmarked: 1 })}>
            ブックマーク
          </CustomMenuItem>
        )}
        <CustomMenuItem href={studyGroupsPath()}>勉強会</CustomMenuItem>
        {flipper.business_matching && (
          <CustomMenuItem href={businessMatchingRootPath()}>
            受発注 (β版)
          </CustomMenuItem>
        )}
      </MenuGroup>
      <MenuDivider />
      <CustomMenuItem href={newContactPath()} target="_blank" rel="noreferrer">
        お問い合わせ
      </CustomMenuItem>
      <CustomMenuItem onClick={logout}>ログアウト</CustomMenuItem>
    </MenuList>
  );
};

const ServiceLinks = () => {
  const [currentLocation, setCurrentLocation] = useState<Location>();

  useEffect(() => {
    setCurrentLocation(location);
  }, []);

  const activeProps = {
    borderBottom: "4px solid",
    borderColor: "primary",
    fontWeight: "bold",
  };

  const flipper = useContext(FlipperContext);

  return (
    <HStack h="full">
      <Link
        px={6}
        href={postsPath()}
        textDecoration="none"
        color="inherit"
        {...(currentLocation?.pathname?.startsWith("/posts") && activeProps)}
        _hover={hoverStyle}
        h="full"
        placeContent="center"
      >
        <Box pt={0.5} as="span">
          Q&A
        </Box>
      </Link>
      <Link
        px={6}
        href={studyGroupsPath()}
        textDecoration="none"
        color="inherit"
        {...(currentLocation?.pathname?.startsWith("/study_groups") &&
          activeProps)}
        _hover={hoverStyle}
        h="full"
        placeContent="center"
      >
        <Box pt={0.5} as="span">
          勉強会
        </Box>
      </Link>
      {flipper.business_matching && (
        <Link
          px={6}
          href={businessMatchingRootPath()}
          textDecoration="none"
          color="inherit"
          {...(currentLocation?.pathname?.startsWith("/business_matching") &&
            activeProps)}
          _hover={hoverStyle}
          h="full"
          placeContent="center"
        >
          <Box pt={0.5} as="span">
            受発注 (β版)
          </Box>
        </Link>
      )}
    </HStack>
  );
};

const MessagesButton = ({
  currentUser,
}: {
  currentUser: SharedCurrentUser | undefined;
}) => {
  const owned_business_entities_has_talk_rooms = currentUser?.is_approved
    ? currentUser.owned_business_entities_has_talk_rooms
    : [];

  if (owned_business_entities_has_talk_rooms.length === 0) return;

  const ContentsForButton = () => {
    return (
      <>
        <Show above="lg">
          <HStack gap={1}>
            <SmsIcon />
            <Text fontSize="sm">メッセージ</Text>
          </HStack>
        </Show>
        <Show below="lg">
          <SmsIcon boxSize={6} />
        </Show>
      </>
    );
  };

  return (
    <>
      {owned_business_entities_has_talk_rooms.length === 1 ? (
        <Link
          px={4}
          href={businessMatchingOwnedBusinessEntityTalkRoomsPath(
            owned_business_entities_has_talk_rooms[0].code,
          )}
          textDecoration="none"
          color="inherit"
          _hover={hoverStyle}
          h="full"
          placeContent="center"
        >
          <ContentsForButton />
        </Link>
      ) : (
        <Menu autoSelect={false}>
          <MenuButton h="full" display="flex" _hover={hoverStyle} px={4}>
            <ContentsForButton />
          </MenuButton>
          <MenuList maxW="xs">
            <Stack gap={0} maxH="50vh" overflow="scroll">
              {owned_business_entities_has_talk_rooms.map(
                ({ code, name }, index) => {
                  const isLast =
                    index === owned_business_entities_has_talk_rooms.length - 1;
                  return (
                    <Box key={code}>
                      <CustomMenuItem
                        href={businessMatchingOwnedBusinessEntityTalkRoomsPath(
                          code,
                        )}
                      >
                        {name}
                      </CustomMenuItem>
                      {!isLast && <MenuDivider />}
                    </Box>
                  );
                },
              )}
            </Stack>
          </MenuList>
        </Menu>
      )}
    </>
  );
};

export default GlobalHeader;
