import "../stylesheets/public.css";
import "dayjs/locale/ja";
import "../bundles/shared/lib/init";

import ReactOnRails from "react-on-rails";
import CommunitiesIndex from "../bundles/public/communities/CommunitiesIndex";
import CommunitiesShow from "../bundles/public/communities/CommunitiesShow";
import CommunitiesNew from "../bundles/public/communities/CommunitiesNew";
import CommunitiesEdit from "../bundles/public/communities/CommunitiesEdit";
import StudyGroupsIndex from "../bundles/public/study_groups/StudyGroupsIndex";
import StudyGroupsShow from "../bundles/public/study_groups/StudyGroupsShow";
import StudyGroupsNew from "../bundles/public/study_groups/StudyGroupsNew";
import StudyGroupsEdit from "../bundles/public/study_groups/StudyGroupsEdit";
import PostsIndex from "../bundles/public/posts/PostsIndex";
import PostsShow from "../bundles/public/posts/PostsShow";
import PostsNew from "../bundles/public/posts/PostsNew";
import PostsEdit from "../bundles/public/posts/PostsEdit";
import HomeIndex from "../bundles/public/home/HomeIndex";
import TermsOfServiceShow from "../bundles/public/terms_of_services/TermsOfServiceShow";
import PrivacyPolicyShow from "../bundles/public/privacy_policies/PrivacyPolicyShow";
import ContactsNew from "../bundles/public/contacts/ContactsNew";
import ConfirmationDonesShow from "../bundles/public/confirmation_dones/ConfirmationDonesShow";
import AnnouncementsIndex from "../bundles/public/announcements/AnnouncementsIndex";
import AnnouncementsShow from "../bundles/public/announcements/AnnouncementsShow";
import ConfirmationSentsShow from "../bundles/public/confirmation_sents/ConfirmationSentsShow";
import ResetPasswordSentsShow from "../bundles/public/reset_password_sents/ResetPasswordSentsShow";
import UsersShow from "../bundles/public/users/UsersShow";
import UserPersonalInfoApplicationsNew from "../bundles/public/user_personal_info_applications/UserPersonalInfoApplicationsNew";
import UserPersonalInfoApplicationsDone from "../bundles/public/user_personal_info_applications/UserPersonalInfoApplicationsDone";
import ProfileBasicsEdit from "../bundles/public/profile/basics/ProfileBasicsEdit";
import ProfileOfficialUsersEdit from "../bundles/public/profile/official-users/ProfileOfficialUsersEdit";
import ProfileGuestUsersEdit from "../bundles/public/profile/guest-users/ProfileGuestUsersEdit";
import ProfileWorkplacesEdit from "../bundles/public/profile/workplaces/ProfileWorkplacesEdit";
import ProfileRegistrationsEdit from "../bundles/public/profile/registrations/ProfileRegistrationsEdit";
import ProfileEmailNotificationSettingsEdit from "../bundles/public/profile/email-notification-settings/ProfileEmailNotificationSettingsEdit";
import ProfileCancelsNew from "../bundles/public/profile/cancels/ProfileCancelsNew";
import ProfileEmailNotificationSettingsEditOld from "../bundles/public/profile/email-notification-settings/ProfileEmailNotificationSettingsEditOld";
import PersonalInfoApplicationsNew from "../bundles/public/profile/personal-info-applications/PersonalInfoApplicationsNew";
import AuthSessionsNew from "../bundles/public/auth/sessions/AuthSessionsNew";
import AuthRegistrationsNew from "../bundles/public/auth/registrations/AuthRegistrationsNew";
import AuthPasswordsNew from "../bundles/public/auth/passwords/AuthPasswordsNew";
import AuthPasswordsEdit from "../bundles/public/auth/passwords/AuthPasswordsEdit";
import BusinessEntitiesIndex from "../bundles/public/business_matching/business_entities/BusinessEntitiesIndex";
import BusinessEntitiesShow from "../bundles/public/business_matching/business_entities/BusinessEntitiesShow";
import ActiveBusinessEntitiesEdit from "../bundles/public/business_matching/active_business_entities/ActiveBusinessEntitiesEdit";
import TalkRoomsIndex from "../bundles/public/business_matching/talk_rooms/TalkRoomsIndex";
import VerificationSentsShow from "../bundles/public/business_matching/verification_sents/VerificationSentsShow";
import ApplicationDonesShow from "../bundles/public/business_matching/application_dones/ApplicationDonesShow";
import OwnedBusinessEntitiesNew from "../bundles/public/business_matching/owned_business_entities/OwnedBusinessEntitiesNew";
import OwnedBusinessEntitiesEdit from "../bundles/public/business_matching/owned_business_entities/OwnedBusinessEntitiesEdit";
import * as Sentry from "@sentry/react";
import Fallback from "../bundles/shared/components/Fallback";

import "../bundles/shared/lib/sentry";
import React from "react";
import theme from "../bundles/public/shared/lib/theme";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FlipperContext } from "../bundles/shared/lib/FlipperContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const withProviders = (Component) => {
  return function InnerComponent(props, railsContext) {
    Sentry.setContext("Additional", {
      current_user_code: railsContext.current_user_code,
      request_id: railsContext.request_id,
    });
    const result = () => (
      <Sentry.ErrorBoundary fallback={<Fallback />}>
        <ChakraProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <FlipperContext.Provider value={railsContext.flipper}>
              <Component {...props} />
            </FlipperContext.Provider>
          </QueryClientProvider>
        </ChakraProvider>
      </Sentry.ErrorBoundary>
    );
    return result;
  };
};

const components = {
  CommunitiesIndex,
  CommunitiesShow,
  CommunitiesNew,
  CommunitiesEdit,
  StudyGroupsIndex,
  StudyGroupsShow,
  StudyGroupsNew,
  StudyGroupsEdit,
  PostsIndex,
  PostsShow,
  PostsNew,
  PostsEdit,
  HomeIndex,
  TermsOfServiceShow,
  PrivacyPolicyShow,
  ContactsNew,
  ConfirmationDonesShow,
  AnnouncementsIndex,
  AnnouncementsShow,
  ConfirmationSentsShow,
  ResetPasswordSentsShow,
  UsersShow,
  UserPersonalInfoApplicationsNew,
  UserPersonalInfoApplicationsDone,
  ProfileBasicsEdit,
  ProfileWorkplacesEdit,
  ProfileRegistrationsEdit,
  ProfileEmailNotificationSettingsEdit,
  ProfileCancelsNew,
  ProfileEmailNotificationSettingsEditOld,
  ProfileOfficialUsersEdit,
  ProfileGuestUsersEdit,
  PersonalInfoApplicationsNew,
  AuthSessionsNew,
  AuthRegistrationsNew,
  AuthPasswordsNew,
  AuthPasswordsEdit,
  BusinessEntitiesIndex,
  BusinessEntitiesShow,
  ActiveBusinessEntitiesEdit,
  TalkRoomsIndex,
  VerificationSentsShow,
  ApplicationDonesShow,
  OwnedBusinessEntitiesNew,
  OwnedBusinessEntitiesEdit,
};

const withProvidersComponents = Object.keys(components).reduce((acc, key) => {
  acc[key] = withProviders(components[key]);
  return acc;
}, {});

ReactOnRails.register(withProvidersComponents);
