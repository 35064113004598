import * as yup from "yup";

export const schema = yup.object().shape({
  temporary_user_input: yup.object().shape({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    nickname: yup.string().trim().required().nickname().label("ニックネーム"),
    architect_license_id: yup.string().trim().required().label("建築士所有資格"),
    architect_type_id: yup.string().trim().required().label("職種"),
    birthday: yup.string().trim().required().label("生年月日"),
    prefecture_id: yup.string().trim().required().label("居住地"),
    industry_id: yup.string().trim().required().label("業種"),
    user_position_id: yup.string().trim().required().label("ポジション"),
  }),
  user_service_referral_source_attributes: yup.object().shape({
    service_referral_source_ids: yup
      .array(yup.string().required())
      .required()
      .min(1, "最低1つ選択してください")
      .label("A-Loopを知ったきっかけ"),
    other_reason: yup.string().when(["service_referral_source_ids", "$otherReasonId"], {
      is: (service_referral_source_ids: string[], otherReasonId: string) =>
        service_referral_source_ids.includes(otherReasonId),
      then: (schema) => schema.trim().required("必須です"),
      otherwise: (schema) => schema,
    }),
    referral_code: yup.string().when(["service_referral_source_ids", "$fromSalesId"], {
      is: (service_referral_source_ids: string[], fromSalesId: string) => {
        return service_referral_source_ids.includes(fromSalesId);
      },
      then: (schema) => schema.trim().required("必須です"),
      otherwise: (schema) => schema,
    }),
  }),
  email: yup.string().trim().email().required().label("メールアドレス"),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  password: yup.string().required().password().label("パスワード"),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  password_confirmation: yup.string().confirmation({
    key: "password",
    message: "パスワードの入力が一致しません",
  }),
  agreement: yup.bool().is([true], "「利用規約」および「個人情報保護方針」に同意してください"),
});
