import React, { ReactNode } from "react";
import { Flex, forwardRef } from "@chakra-ui/react";

const InfoItem = forwardRef(({ children }: { children: ReactNode }, ref) => {
  return (
    <Flex ref={ref} direction={{ base: "column", lg: "row" }} columnGap={8}>
      {children}
    </Flex>
  );
});

export default InfoItem;
