import {
  Box,
  Container,
  Flex,
  Heading,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import {
  businessMatchingOwnedBusinessEntitiesPath,
  businessMatchingRootPath,
  validateBusinessMatchingOwnedBusinessEntitiesPath,
} from "../../../../routes";
import { Button, Header } from "../../shared/components/atoms";
import Background from "../../shared/components/atoms/Background";
import FixedBackgroundBuildingImage from "../../shared/components/atoms/FixedBackgroundBuildingImage";
import {
  FormLabel,
  Input,
  InputError,
  Radio,
} from "../../shared/components/atoms/form";
import Select from "../../shared/components/atoms/form/Select";
import SectionTitle from "../../shared/components/atoms/SectionTitle";
import ShadowCard from "../../shared/components/atoms/ShadowCard";
import SiteSeal from "../../shared/components/atoms/SiteSeal";
import Application from "../../shared/components/layouts/Application";
import {
  SharedApprovedCurrentUser,
  SharedIndustries,
  SharedPrefectures,
} from "../../shared/lib/types";
import useRequest from "../../shared/lib/useRequest";
import { Flash } from "../../../shared/lib/types";
import GoBackLink from "../../shared/components/atoms/GoBackLink";
import useFileState from "../../shared/lib/useFileState";
import Dropzone from "../../shared/components/atoms/Dropzone";
import DropzonePreview from "../../shared/components/atoms/DropzonePreview";
import { uploadFiles } from "../../../shared/lib/uploadFile";
import Confirmation from "./Confirmation";
import Checkbox from "../../shared/components/atoms/form/Checkbox";
import {
  BusinessEntityFormData,
  useBusinessEntityForm,
} from "./lib/useBusinessEntityForm";
import useAutokana from "../../shared/lib/useAutokana";
import getEstablishmentYears from "./lib/getEstablishmentYears";
import { handleValidateEmailResponse } from "./lib/handleValidateEmailResponse";
import HelpMessage from "../../shared/components/atoms/HelpMessage";

const OwnedBusinessEntitiesNew = ({
  flash,
  currentUser,
  prefectures,
  industries,
}: {
  flash: Flash;
  currentUser: SharedApprovedCurrentUser;
  prefectures: SharedPrefectures;
  industries: SharedIndustries;
}) => {
  const {
    fillAddressByPostalCode,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { isSubmitting },
  } = useBusinessEntityForm({
    defaultValues: {
      business_type: "",
      name: "",
      name_kana: "",
      postal_code: "",
      prefecture_id: "",
      address1: "",
      address2: "",
      address3: "",
      phone_number: "",
      email: currentUser.email,
      industry_id: "",
      number_of_employees: "",
      establishment_year: undefined,
      establishment_month: undefined,
      capital_ten_thousand_yen: "",
      president_name: "",
      website_url: "",
      facebook_url: "",
      instagram_url: "",
      note_url: "",
      pinterest_url: "",
      threads_url: "",
      tiktok_url: "",
      x_url: "",
      youtube_url: "",
      agreement: false,
    },
    prefectures: prefectures,
  });
  const nameAutoKana = useAutokana("#name", "#name_kana");
  const logoImageFileFormRef = useRef<HTMLDivElement>(null);
  const logoImageFile = useFileState({
    formRef: logoImageFileFormRef,
    required: true,
  });
  const [showConfirmation, setShowConfirmation] = useState(false);

  const businessTypes = [
    { value: "company", label: "法人" },
    { value: "self_employment", label: "個人事業主" },
  ];

  const onConfirm = async (data: BusinessEntityFormData) => {
    if (!logoImageFile.validate()) return;
    if (!(await validateEmail(data))) {
      return;
    }

    setShowConfirmation(true);
    window.scrollTo(0, 0);
  };

  const request = useRequest();
  const validateEmail = async (data: BusinessEntityFormData) => {
    const res = await request(
      validateBusinessMatchingOwnedBusinessEntitiesPath(),
      "POST",
      { business_entity_info_application: { email: data.email } },
    );
    return await handleValidateEmailResponse({
      response: res,
      methods: { setError, clearErrors },
    });
  };
  const onSubmit = async (data: BusinessEntityFormData) => {
    if (!logoImageFile.validate()) return;
    if (!(await validateEmail(data))) {
      return;
    }

    const { establishment_year, establishment_month, ...restData } = data;

    const results = await uploadFiles({ files: [logoImageFile.file!] });

    const requestData = {
      first_business_entity_info_application: {
        ...restData,
        establishment_year_month: `${establishment_year}/${establishment_month}`,
        logo_image: results[0].blob.signed_id,
      },
    };

    const res = await request(
      businessMatchingOwnedBusinessEntitiesPath(),
      "POST",
      requestData,
    );
    if (res.ok) {
      const json = await res.json();
      location.href = json.redirect_url;
    }
  };

  return (
    <Application flash={flash} currentUser={currentUser}>
      <FixedBackgroundBuildingImage />
      <Header currentUser={currentUser} />
      <Background>
        <Container maxW="2xl" mt={{ base: 6, lg: 20 }} zIndex={1}>
          <GoBackLink href={businessMatchingRootPath()}>
            事業者一覧に戻る
          </GoBackLink>
          <Box mt={2}>
            <ShadowCard>
              <Heading size="lg" mt={{ base: 6, md: 8 }} textAlign="center">
                受発注（β版）サービス
                <Box
                  as="span"
                  display={{ base: "block", md: "inline" }}
                  w="100%"
                >
                  利用申請
                </Box>
              </Heading>
              {showConfirmation && (
                <Confirmation
                  values={getValues()}
                  logoImagePreview={logoImageFile.preview!}
                  businessTypes={businessTypes}
                  prefectures={prefectures}
                  industries={industries}
                  isSubmitting={isSubmitting}
                  onSubmit={handleSubmit(onSubmit, () =>
                    logoImageFile.validate(),
                  )}
                  onClickFix={() => setShowConfirmation(false)}
                />
              )}
              <Box
                display={showConfirmation ? "none" : "block"}
                as="form"
                onSubmit={handleSubmit(onConfirm, () =>
                  logoImageFile.validate(),
                )}
              >
                <SectionTitle mt={14}>事業区分</SectionTitle>
                <Stack gap={2} mt={7}>
                  <FormLabel required>どちらの事業区分ですか？</FormLabel>
                  <Controller
                    name="business_type"
                    control={control}
                    render={({ field, fieldState }) => {
                      const { ref, ...rest } = field;
                      return (
                        <>
                          <RadioGroup {...rest}>
                            <Stack>
                              {businessTypes.map((businessType) => (
                                <Radio
                                  value={businessType.value}
                                  key={businessType.value}
                                  ref={ref}
                                >
                                  {businessType.label}
                                </Radio>
                              ))}
                            </Stack>
                          </RadioGroup>
                          {fieldState.error?.message !== undefined && (
                            <InputError>{fieldState.error.message}</InputError>
                          )}
                        </>
                      );
                    }}
                  />
                </Stack>
                <SectionTitle mt={14}>法人または個人事業主情報</SectionTitle>
                <Stack gap={7} mt={7}>
                  <Box>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          id="name"
                          {...field}
                          required
                          label="事業者名"
                          error={error?.message}
                          onInput={() => {
                            setValue("name_kana", nameAutoKana?.getFurigana());
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="name_kana"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          id="name_kana"
                          {...field}
                          label="事業者名かな"
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box maxW="326px">
                    <Controller
                      name="postal_code"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          required
                          label="郵便番号"
                          error={error?.message}
                          onChange={async (e) => {
                            field.onChange(e);
                            await fillAddressByPostalCode(e.target.value);
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box maxW="326px">
                    <Controller
                      name="prefecture_id"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          required
                          label="都道府県"
                          error={error?.message ?? ""}
                          {...field}
                        >
                          <option value="" key="empty">
                            選択してください
                          </option>
                          {prefectures.map((prefecture) => (
                            <option value={prefecture.id} key={prefecture.id}>
                              {prefecture.name}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="address1"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          required
                          label="市区町村"
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="address2"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          required
                          label="番地"
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="address3"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          label="建物名"
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box maxW="326px">
                    <Controller
                      name="phone_number"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          required
                          label="電話番号"
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Box>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            required
                            label="受発注で利用するメールアドレス"
                            error={error?.message}
                          />
                        )}
                      />
                    </Box>
                    <Popover matchWidth>
                      <PopoverTrigger>
                        <HelpMessage cursor="pointer" mt={1}>
                          受発注で利用するメールアドレスについて
                        </HelpMessage>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                          <Text fontSize="sm">
                            すでにご登録いただいているログイン用メールアドレスとは異なるメールアドレスをご登録いただいた場合、「受発注に関する通知」のみこちらへお送りいたします。
                          </Text>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Box>
                  <Box maxW="326px">
                    <Controller
                      name="industry_id"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          required
                          label="主業種"
                          error={error?.message ?? ""}
                          {...field}
                        >
                          <option value="" key="empty">
                            選択してください
                          </option>
                          {industries.map((industry) => (
                            <option value={industry.id} key={industry.id}>
                              {industry.name}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                  </Box>
                  <Box>
                    <Box maxW="326px">
                      <Controller
                        name="number_of_employees"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            required
                            label="従業員数"
                            error={error?.message}
                          />
                        )}
                      />
                    </Box>
                    <Text fontSize="xs" color="#6D787D" mt={1}>
                      個人事業主で従業員がいない場合は0を入力してください。
                    </Text>
                  </Box>
                  <Box maxW="400px">
                    <FormLabel required>設立年月</FormLabel>
                    <Stack gap={0.5}>
                      <Flex gap={4} align="center" direction="row">
                        <Flex gap={1} align="center" flex={1}>
                          <Controller
                            name="establishment_year"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <Select
                                required
                                error={error?.message ?? ""}
                                {...field}
                                aria-label="設立年"
                              >
                                <option value="" key="empty"></option>
                                {getEstablishmentYears().map((year) => (
                                  <option value={year} key={year}>
                                    {year}
                                  </option>
                                ))}
                              </Select>
                            )}
                          />
                          <Text as="span">年</Text>
                        </Flex>
                        <Flex gap={1} align="center" flex={1}>
                          <Controller
                            name="establishment_month"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <Select
                                required
                                error={error?.message ?? ""}
                                {...field}
                                aria-label="設立月"
                              >
                                <option value="" key="empty"></option>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                                  (month) => (
                                    <option value={month} key={month}>
                                      {month}
                                    </option>
                                  ),
                                )}
                              </Select>
                            )}
                          />
                          <Text as="span">月</Text>
                        </Flex>
                      </Flex>
                    </Stack>
                  </Box>
                  <Box>
                    <Box maxW="240px">
                      <FormLabel required htmlFor="capital_ten_thousand_yen">
                        資本金
                      </FormLabel>
                      <Flex gap={1} align="center" flex={1}>
                        <Controller
                          name="capital_ten_thousand_yen"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <Input
                              {...field}
                              required
                              error={error?.message}
                              id="capital_ten_thousand_yen"
                            />
                          )}
                        />
                        <Text as="span" whiteSpace="nowrap">
                          万円
                        </Text>
                      </Flex>
                    </Box>
                    <Text fontSize="xs" color="#6D787D" mt={1}>
                      資本金を持たない場合は、「0」を入力してください。
                    </Text>
                  </Box>
                  <Stack ref={logoImageFileFormRef} gap={1}>
                    <FormLabel required>事業者ロゴ</FormLabel>
                    {logoImageFile.file == null ? (
                      <Dropzone
                        onDrop={(files) => {
                          logoImageFile.setFile(files[0]);
                        }}
                        name="logo_image"
                        message={
                          "PNGもしくはJPGファイルで、5MB未満のサイズでアップロードしてください。"
                        }
                        error={logoImageFile.error}
                        accept={{
                          "image/png": [],
                          "image/jpeg": [],
                        }}
                      />
                    ) : (
                      <DropzonePreview fileState={logoImageFile} />
                    )}
                  </Stack>
                  <Box maxW="326px">
                    <Controller
                      name="president_name"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          required
                          label="代表者氏名"
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="website_url"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          label="Webサイト"
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                </Stack>
                <SectionTitle mt={14}>SNS</SectionTitle>
                <Stack gap={7} mt={7}>
                  <Box maxW="326px">
                    <Controller
                      name="facebook_url"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          label="Facebook"
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box maxW="326px">
                    <Controller
                      name="instagram_url"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          label="Instagram"
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box maxW="326px">
                    <Controller
                      name="note_url"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input {...field} label="note" error={error?.message} />
                      )}
                    />
                  </Box>
                  <Box maxW="326px">
                    <Controller
                      name="pinterest_url"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          label="Pinterest"
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box maxW="326px">
                    <Controller
                      name="threads_url"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          label="Threads"
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box maxW="326px">
                    <Controller
                      name="tiktok_url"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          label="TikTok"
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box maxW="326px">
                    <Controller
                      name="x_url"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          label="X（旧Twitter）"
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box maxW="326px">
                    <Controller
                      name="youtube_url"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          label="YouTube"
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                </Stack>
                <Box
                  p={0}
                  mt={10}
                  borderBottomColor="brand.500"
                  borderBottomWidth="2px"
                />
                <Stack gap={2} mt={{ base: 8, lg: 12 }}>
                  <Controller
                    name="agreement"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <Checkbox {...field}>
                          <Text>「利用規約」に同意する</Text>
                        </Checkbox>
                        {fieldState.error !== undefined && (
                          <InputError pl={6}>
                            {fieldState.error.message}
                          </InputError>
                        )}
                      </>
                    )}
                  />
                  <Text fontSize="xs" pl="27px">
                    「
                    <Link
                      color="textLink"
                      textDecoration="underline"
                      _hover={{ textDecoration: "none" }}
                      // TODO: 利用規約が準備されたら設定する
                      href=""
                      target="_blank"
                      rel="noreferrer"
                    >
                      利用規約
                    </Link>
                    」をご確認いただき、チェックをお願いします。
                  </Text>
                </Stack>
                <Flex justify="center" mt={{ base: 10, lg: 12 }}>
                  <Button type="submit" isLoading={false} isDisabled={false}>
                    入力内容確認へ進む
                  </Button>
                </Flex>
              </Box>
              <Box mt={10}>
                <SiteSeal />
              </Box>
            </ShadowCard>
          </Box>
        </Container>
      </Background>
    </Application>
  );
};

export default OwnedBusinessEntitiesNew;
