import React, { ReactNode, useEffect } from "react";
import useFlash from "../../lib/useFlash";
import RegistrationRequiredModal from "../atoms/RegistrationRequiredModal";
import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { SharedCurrentUser } from "../../lib/types";
import { Button } from "../atoms";
import { newUserDatabaseAuthenticationRegistrationPath } from "../../../../../routes";
import { isShowFloatingRegisterButton } from "../../lib/isShowFloatingRegisterButton";

const Application = ({
  children,
  flash = {},
  currentUser = null,
  disableFloatingRegisterButton = false,
}: {
  children: ReactNode;
  flash: Record<string, string>;
  currentUser?: SharedCurrentUser;
  disableFloatingRegisterButton?: boolean;
}) => {
  const showFlash = useFlash();
  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => showFlash(flash), [showFlash, flash]);
  useEffect(() => {
    if (
      new URLSearchParams(location.search).get(
        "open_registration_require_modal",
      ) === "1"
    ) {
      onOpen();
    }
  }, [onOpen]);

  const removeNotApplicationingQueryParams = () => {
    const url = new URL(location.href);
    // location.hrefを使うと不要なリロードが走るためreplaceStateを使う
    url.searchParams.delete("open_registration_require_modal");
    window.history.replaceState(null, "", url);
  };

  return (
    <Box>
      <RegistrationRequiredModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          // open_registration_require_modal=1を削除しないと「戻る」とかしたときに再度モーダルが表示されてしまうため削除
          removeNotApplicationingQueryParams();
        }}
        currentUser={currentUser}
      />
      {children}
      {isShowFloatingRegisterButton({ currentUser }) &&
        !disableFloatingRegisterButton && (
          <Flex
            justify={"center"}
            py={{ base: 6, md: 5 }}
            bgColor="rgba(0, 0, 0, 0.7)"
            position="sticky"
            bottom={0}
            zIndex={100}
          >
            <Button
              as="a"
              href={newUserDatabaseAuthenticationRegistrationPath()}
            >
              会員登録（無料）
            </Button>
          </Flex>
        )}
    </Box>
  );
};

export default Application;
