import React, { useContext, useState } from "react";
import { Input } from "./form";
import { InputProps } from "./form/Input";
import { forwardRef, InputRightElement } from "@chakra-ui/react";
import VisibilityIcon from "../icons/VisibilityIcon";
import VisibilityOffIcon from "../icons/VisibilityOffIcon";
import { FlipperContext } from "../../../../shared/lib/FlipperContext";

const PasswordInput = forwardRef<InputProps, "input">(({ ...props }, ref) => {
  const [show, setShow] = useState(false);
  const flipper = useContext(FlipperContext);
  return (
    <Input
      {...props}
      type={show ? "text" : "password"}
      ref={ref}
      rightElement={() =>
        flipper.show_password_feature && (
          <InputRightElement
            as="button"
            type="button"
            onClick={() => setShow(!show)}
            color="#99A9B0"
            aria-label={show ? "パスワードを非表示" : "パスワードを表示"}
          >
            {show ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </InputRightElement>
        )
      }
    />
  );
});

export default PasswordInput;
