import {
  Box,
  Flex,
  Heading,
  InputProps,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import "cropperjs/dist/cropper.css";
import React, { useCallback, useEffect } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  newProfilePersonalInfoApplicationPath,
  profilePersonalInfoApplicationPath,
  validateProfilePersonalInfoApplicationPath,
} from "../../../../routes";
import useRequest from "../../shared/lib/useRequest";
import { Button } from "../../shared/components/atoms";
import {
  FormLabel,
  Input,
  InputError,
  Radio,
} from "../../shared/components/atoms/form";
import Textarea from "../../shared/components/atoms/form/Textarea";
import {
  ProfileItem,
  ProfileItemLeft,
  ProfileItemRight,
} from "../components/ProfileItem";
import ProfilePageLayout from "../components/ProfilePageLayout";
import {
  ProfilePersonalInfoApplicationsPersonalInfo,
  SharedArchitectLicenses,
  SharedCurrentUser,
} from "../../shared/lib/types";
import { Flash } from "../../../shared/lib/types";
import { useDebouncedCallback } from "use-debounce";
import SiteSeal from "../../shared/components/atoms/SiteSeal";
import useFileState from "../../shared/lib/useFileState";
import ArchitectLicenseImageField from "../../shared/components/atoms/ArchitectLicenseImageField";
import { uploadFile } from "../../../shared/lib/uploadFile";
import useAutokana from "../../shared/lib/useAutokana";
import SampleModalButton from "../../user/shared/components/SampleModalButton";
import WarningMessage from "../../shared/components/atoms/WarningMessage";

const schema = yup.object({
  family_name: yup.string().trim().required().label("姓"),
  given_name: yup.string().trim().required().label("名"),
  family_name_kana: yup.string().trim().required().hiragana().label("姓"),
  given_name_kana: yup.string().trim().required().hiragana().label("名"),
  architect_license_id: yup.string().trim().required().label("建築士所有資格"),
  architect_number: yup
    .string()
    .trim()
    .required()
    .matches(/^\d+$/, "半角数字で入力してください")
    .label("建築士番号"),
  is_same_name: yup.string().trim().required().label("ご登録氏名について"),
  different_name_reason: yup
    .string()
    .trim()
    .ensure()
    .when("is_same_name", {
      is: "no",
      then: (schema) => schema.required(),
    })
    .label("ご登録の氏名と建築士証のお名前が異なる理由"),
});

type FormData = yup.InferType<typeof schema>;

const NameField = ({
  control,
  isFormDisabled,
  label,
  name,
  ...props
}: {
  control: Control<FormData>;
  isFormDisabled: boolean;
  label: string;
  name: "family_name" | "family_name_kana" | "given_name" | "given_name_kana";
} & InputProps) => {
  return (
    <Flex gap={4} alignItems="center">
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            error={error?.message ?? ""}
            id={name}
            isDisabled={isFormDisabled}
            {...props}
          />
        )}
      />
    </Flex>
  );
};

const Page = ({
  architect_licenses,
  personal_info,
  active_personal_info_application,
  current_user,
}: {
  architect_licenses: SharedArchitectLicenses;
  personal_info: ProfilePersonalInfoApplicationsPersonalInfo;
  active_personal_info_application: ProfilePersonalInfoApplicationsPersonalInfo;
  current_user: NonNullable<SharedCurrentUser>;
}) => {
  const defaultValuesSource = active_personal_info_application ??
    personal_info ?? {
      family_name: "",
      given_name: "",
      family_name_kana: "",
      given_name_kana: "",
      architect_license_id: "",
      architect_number: "",
      is_same_name: "",
      different_name_reason: "",
    };

  const defaultValues = {
    ...defaultValuesSource,
    architect_license_id: defaultValuesSource.architect_license_id.toString(),
    is_same_name:
      defaultValuesSource.different_name_reason === "" ? "yes" : "no",
  };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    formState: { isDirty, isSubmitting },
  } = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const architectLicenseImageFile = useFileState({ required: true });

  const request = useRequest();

  const validate = useCallback(async () => {
    let result = true;
    const res = await request(
      validateProfilePersonalInfoApplicationPath(),
      "POST",
      {
        personal_info_application: getValues(),
      },
    );

    await res.json().then((json) => {
      if (json.architect_number != null) {
        setError("architect_number", {
          type: "custom",
          message: json.architect_number,
        });
        result = false;
      }
    });
    if (!architectLicenseImageFile.validate()) {
      result = false;
    }
    return result;
  }, [architectLicenseImageFile, getValues, request, setError]);

  const onSubmit = async (data: FormData) => {
    if (!(await validate())) {
      return;
    }

    const { blob } = await uploadFile({
      file: architectLicenseImageFile.file!,
    });

    const res = await request(profilePersonalInfoApplicationPath(), "POST", {
      personal_info_application: {
        ...data,
        architect_license_image: blob.signed_id,
      },
    });
    if (res.ok) {
      location.href = newProfilePersonalInfoApplicationPath();
    }
  };

  const onCancel = async () => {
    const res = await request(profilePersonalInfoApplicationPath(), "DELETE");
    if (res.ok) {
      location.href = newProfilePersonalInfoApplicationPath();
    }
  };
  if (watch("is_same_name") === "yes" && watch("different_name_reason") !== "")
    setValue("different_name_reason", "");

  const debounceValidate = useDebouncedCallback(validate, 500);

  const architectNumber = watch("architect_number");

  useEffect(() => {
    void debounceValidate();
  }, [architectNumber, debounceValidate]);

  const isFormDisabled = active_personal_info_application !== null;

  const familyNameAutokana = useAutokana("#family_name", "#family_name_kana");
  const givenNameAutokana = useAutokana("#given_name", "#given_name_kana");

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit, validate)}>
      <Box
        background="#FFF"
        px={{ base: 5, md: 8 }}
        py={{ base: 6, md: 10 }}
        borderRadius="2px"
      >
        <Heading as="h1" size="lg">
          本人確認情報
        </Heading>
        <WarningMessage mt={{ base: 3, md: 4 }}>
          {isFormDisabled ? (
            <>
              現在、審査中のため一時的に編集が不可になっています。
              <br />
              内容を変更したい場合はページ下部の「申請を取り下げて変更」より変更手続きを行ってください。
            </>
          ) : (
            <>
              本人確認情報を更新した場合、弊社で内容を審査します。
              <br />
              審査終了後、更新いただいた内容に反映されます。
            </>
          )}
        </WarningMessage>
        <Stack mt={{ base: 6, md: 10 }} gap={6}>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel required>氏名</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Flex
                gap={6}
                rowGap={{ base: 2, lg: 6 }}
                direction={{ base: "column", lg: "row" }}
              >
                <Box flex="1">
                  <NameField
                    name="family_name"
                    control={control}
                    label="姓"
                    isFormDisabled={isFormDisabled}
                    onInput={() => {
                      setValue(
                        "family_name_kana",
                        familyNameAutokana?.getFurigana() ?? "",
                      );
                    }}
                    aria-label="姓（漢字）"
                  />
                </Box>
                <Box flex="1">
                  <NameField
                    name="given_name"
                    control={control}
                    label="名"
                    isFormDisabled={isFormDisabled}
                    onInput={() => {
                      setValue(
                        "given_name_kana",
                        givenNameAutokana?.getFurigana() ?? "",
                      );
                    }}
                    aria-label="名（漢字）"
                  />
                </Box>
              </Flex>
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel required>氏名ふりがな</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Flex
                gap={6}
                rowGap={{ base: 2, lg: 6 }}
                direction={{ base: "column", lg: "row" }}
              >
                <Box flex="1">
                  <NameField
                    name="family_name_kana"
                    control={control}
                    label="姓"
                    isFormDisabled={isFormDisabled}
                    aria-label="姓（かな）"
                  />
                </Box>
                <Box flex="1">
                  <NameField
                    name="given_name_kana"
                    control={control}
                    label="名"
                    isFormDisabled={isFormDisabled}
                    aria-label="名（かな）"
                  />
                </Box>
              </Flex>
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel required htmlFor="architect_license_id">
                建築士所有資格
              </FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="architect_license_id"
                control={control}
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <>
                    <RadioGroup
                      mt={2}
                      gap={2}
                      display="flex"
                      flexDirection="column"
                      {...field}
                      id="architect_license_id"
                      isDisabled={isFormDisabled}
                    >
                      {architect_licenses.map(({ id, name }) => (
                        <Radio
                          value={id.toString()}
                          key={id}
                          isInvalid={error != null}
                          ref={ref}
                        >
                          {name}
                        </Radio>
                      ))}
                    </RadioGroup>
                    {error && <InputError>{error.message}</InputError>}
                  </>
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel required htmlFor="architect_number">
                建築士番号
              </FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="architect_number"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    error={error?.message ?? ""}
                    id="architect_number"
                    isDisabled={isFormDisabled}
                  />
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          {!current_user.personal_info_applicationing && (
            <ProfileItem>
              <ProfileItemLeft>
                <Stack gap={0}>
                  <FormLabel required>建築士免許証明書 画像</FormLabel>
                  <Box>
                    <SampleModalButton />
                  </Box>
                </Stack>
              </ProfileItemLeft>
              <ProfileItemRight>
                <ArchitectLicenseImageField
                  fileState={architectLicenseImageFile}
                />
              </ProfileItemRight>
            </ProfileItem>
          )}
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel required>登録氏名と建築士証名について</FormLabel>
              <Text fontSize={{ base: "xs", sm: "sm" }}>
                ご登録の氏名と建築士免許証明書に記載されている名前は同一ですか？
              </Text>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="is_same_name"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <RadioGroup
                      display="flex"
                      {...field}
                      isDisabled={isFormDisabled}
                      flexDirection="column"
                      gap={2}
                    >
                      {[
                        { value: "yes", label: "同一である" },
                        { value: "no", label: "異なっている" },
                      ].map(({ value, label }) => (
                        <Radio value={value} key={value}>
                          {label}
                        </Radio>
                      ))}
                    </RadioGroup>
                    {fieldState.error?.message !== undefined && (
                      <InputError>{fieldState.error?.message}</InputError>
                    )}
                  </>
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          {watch("is_same_name") === "no" && (
            <ProfileItem>
              <ProfileItemLeft>
                <FormLabel required>登録氏名と建築士証名が異なる理由</FormLabel>
              </ProfileItemLeft>
              <ProfileItemRight>
                <Controller
                  name="different_name_reason"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Textarea
                      placeholder="例：婚姻により氏名が変わったため"
                      error={fieldState.error?.message ?? ""}
                      {...field}
                      isDisabled={isFormDisabled}
                    />
                  )}
                />
              </ProfileItemRight>
            </ProfileItem>
          )}
        </Stack>
      </Box>
      <Box mt={{ base: 8, md: 10 }} textAlign="center">
        {isFormDisabled ? (
          <Text as="span">
            上記内容に誤りがある場合は
            <Text
              as="button"
              type="button"
              color="textLink"
              textDecoration="underline"
              _hover={{ textDecoration: "none" }}
              onClick={onCancel}
            >
              申請を取り下げて変更
            </Text>
          </Text>
        ) : (
          <Button
            maxW="fit-content"
            type="submit"
            isLoading={isSubmitting}
            isDisabled={!isDirty}
          >
            変更審査を依頼する
          </Button>
        )}
      </Box>
    </Stack>
  );
};

const PersonalInfoApplicationsNew = ({
  architectLicenses,
  personalInfo,
  activePersonalInfoApplication,
  flash,
  currentUser,
}: {
  architectLicenses: SharedArchitectLicenses;
  personalInfo: ProfilePersonalInfoApplicationsPersonalInfo;
  activePersonalInfoApplication: ProfilePersonalInfoApplicationsPersonalInfo;
  flash: Flash;
  currentUser: NonNullable<SharedCurrentUser>;
}) => {
  return (
    <ProfilePageLayout current_user={currentUser} flash={flash}>
      <Page
        active_personal_info_application={activePersonalInfoApplication}
        architect_licenses={architectLicenses}
        current_user={currentUser}
        personal_info={personalInfo}
      />
      <Box mt={6}>
        <SiteSeal />
      </Box>
    </ProfilePageLayout>
  );
};

export default PersonalInfoApplicationsNew;
