import {
  Box,
  CheckboxGroup,
  Container,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  editProfileEmailNotificationSettingPath,
  editProfileRegistrationPath,
  rootPath,
  userDatabaseAuthenticationRegistrationPath,
} from "../../../../routes";
import { Flash } from "../../../shared/lib/types";
import { Button, Header } from "../../shared/components/atoms";
import Background from "../../shared/components/atoms/Background";
import PasswordInput from "../../shared/components/atoms/PasswordInput";
import ShadowCard from "../../shared/components/atoms/ShadowCard";
import {
  FormLabel,
  InputError,
  Textarea,
} from "../../shared/components/atoms/form";
import Checkbox from "../../shared/components/atoms/form/Checkbox";
import Application from "../../shared/components/layouts/Application";
import {
  ProfileCancelsCancelReason,
  SharedCurrentUser,
} from "../../shared/lib/types";
import useRequest from "../../shared/lib/useRequest";
import { schema } from "./lib/schema";

type FormType = yup.InferType<typeof schema>;

const ProfileCancelsNew = ({
  cancelReasons,
  flash,
  currentUser,
}: {
  cancelReasons: ProfileCancelsCancelReason;
  flash: Flash;
  currentUser: NonNullable<SharedCurrentUser>;
}) => {
  const otherReasonId = cancelReasons.find((it) => it.name == "その他")?.id;
  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      cancel_detail_attributes: {
        cancel_reason_ids: [] as string[],
        other_reason: "",
        other_opnion: "",
      },
      current_password: "",
    },
    resolver: yupResolver(schema),
    context: { otherReasonId },
  });

  const request = useRequest({ isIgnoreUnexpectedError: true });

  const onSubmit = async (data: FormType) => {
    const res = await request(
      userDatabaseAuthenticationRegistrationPath(),
      "DELETE",
      data,
    );

    if (res.ok) {
      location.href = rootPath();
    } else {
      const body = await res.json();
      if (body.errors.current_password != null) {
        setError("current_password", { message: body.errors.current_password[0] }, { shouldFocus: true })
      }
    }
  };

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} />
      <Background>
        <Container maxW="container.md" mt={{ base: 10, md: 32 }}>
          <ShadowCard>
            <Heading size="lg" textAlign={"center"}>
              A-Loopを退会する
            </Heading>
            <Text textAlign={"center"} mt={4}>
              <Box as="span" display={"inline-block"}>
                <Box as="span" display="inline-block">
                  退会は即時反映され、
                </Box>
                <Box as="span" display="inline-block">
                  一度退会すると復活できません。
                </Box>
              </Box>
              <Box as="span" display={"inline-block"}>
                <Box as="span" display="inline-block">
                  よろしければ以下から
                </Box>
                <Box as="span" display="inline-block">
                  退会手続きを進めてください。
                </Box>
              </Box>
            </Text>
            <Stack as="form" onSubmit={handleSubmit(onSubmit)} gap={8} mt={8}>
              <Controller
                control={control}
                name="cancel_detail_attributes.cancel_reason_ids"
                render={({
                  field: { ref, value, ...field },
                  fieldState: { error },
                }) => (
                  <Box>
                    <FormLabel required>退会理由</FormLabel>
                    <Stack mt={1} gap={{ base: 4, md: 2 }}>
                      <CheckboxGroup {...field} value={value}>
                        {cancelReasons.map((cancelReason) => (
                          <Box key={cancelReason.id}>
                            <Checkbox
                              value={cancelReason.id.toString()}
                              ref={ref}
                              isInvalid={error != null}
                            >
                              {cancelReason.name}
                            </Checkbox>
                            {cancelReason.name == "メール通知が多かった" &&
                              value.includes(cancelReason.id.toString()) && (
                                <EmailSettingLink />
                              )}
                            {cancelReason.id == otherReasonId &&
                              value.includes(otherReasonId.toString()) && (
                                <Controller
                                  control={control}
                                  name="cancel_detail_attributes.other_reason"
                                  render={({
                                    field,
                                    fieldState: { error },
                                  }) => (
                                    <Textarea
                                      placeholder="よろしければ詳しくお教えください"
                                      message="500文字以内"
                                      mt={1}
                                      rows={3}
                                      error={error?.message}
                                      {...field}
                                    />
                                  )}
                                />
                              )}
                          </Box>
                        ))}
                      </CheckboxGroup>
                      {error && <InputError>{error.message}</InputError>}
                    </Stack>
                  </Box>
                )}
              />
              <Box>
                <Controller
                  control={control}
                  name="cancel_detail_attributes.other_opnion"
                  render={({ field, fieldState: { error } }) => (
                    <Textarea
                      label="A-Loopへのご意見"
                      rows={4}
                      message="1000文字以内"
                      error={error?.message}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box py={6} px={8} bgColor="brand.50">
                <Controller
                  control={control}
                  name="current_password"
                  render={({ field, fieldState: { error } }) => (
                    <PasswordInput
                      label="確認のためパスワードを入力"
                      error={error?.message}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Flex gap={3} justifyContent={"center"} wrap={"wrap"}>
                <Button
                  as="a"
                  color="gray"
                  flex={1}
                  size="sm"
                  href={editProfileRegistrationPath()}
                  maxW={40}
                  minW={40}
                >
                  退会をキャンセル
                </Button>
                <Button
                  type="submit"
                  color="red"
                  size="sm"
                  isLoading={isSubmitting}
                  flex={1}
                  maxW={40}
                  minW={40}
                >
                  退会する
                </Button>
              </Flex>
            </Stack>
          </ShadowCard>
        </Container>
      </Background>
    </Application>
  );
};

const EmailSettingLink = () => (
  <Text fontSize="xs" color="gray">
    「
    <Link
      href={editProfileEmailNotificationSettingPath()}
      color="gray"
      target="_blank"
      rel="noreferrer"
    >
      メール受信設定
    </Link>
    」からメールの配信停止を行うことができます。
  </Text>
);

export default ProfileCancelsNew;
