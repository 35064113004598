import { Text } from "@chakra-ui/react";
import React from "react";
import { SharedCurrentUser } from "../../lib/types";
import Overlay from "./Overlay";
import RegistrationButton from "./RegisterOrLoginButtons";

const NotApprovedUserOverlay = ({
  currentUser,
}: {
  currentUser: SharedCurrentUser;
}) => {
  return (
    <Overlay
      background="rgba(60, 170, 145, 0.70)"
      backdropFilter="blur(2px)"
      p={4}
    >
      <Text
        color="white"
        textAlign="center"
        fontWeight="bold"
        fontSize={{ base: "sm", sm: "md" }}
      >
        {currentUser == null
          ? "会員登録が完了すると、コメントを閲覧したり発言をすることが可能になります"
          : currentUser.is_temporary && !currentUser.personal_info_applicationing
            ? "本人確認が完了すると、コメントを閲覧したり発言をすることが可能になります"
            : "本人確認の審査が完了するまでお待ちください。"}
      </Text>
      {(currentUser == null || !currentUser.personal_info_applicationing) && (
        <RegistrationButton
          currentUser={currentUser}
          mt={1}
          fontSize={"sm"}
          loginButtonProps={{ color: "gray" }}
        />
      )}
    </Overlay>
  );
};

export default NotApprovedUserOverlay;
