import * as yup from "yup";

export const schema = yup.object().shape({
  current_password: yup.string().required().label("パスワード"),
  cancel_detail_attributes: yup
    .object()
    .required()
    .shape({
      cancel_reason_ids: yup
        .array(yup.string().required())
        .required()
        .min(1, "最低1つ選択してください"),
      other_reason: yup.string().max(500).when(["cancel_reason_ids", "$otherReasonId"], {
        is: (cancel_reason_ids: string[], otherReasonId: string) => {
          return cancel_reason_ids.includes(otherReasonId.toString());
        },
        then: (schema) => schema.trim().required("必須です"),
        otherwise: (schema) => schema,
      }).label("その他の理由"),
      other_opnion: yup.string().ensure().max(1000).label("その他ご意見"),
    }),
});
