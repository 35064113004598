import {
  Box,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  Flex,
  forwardRef,
} from "@chakra-ui/react";
import React, { ReactNode, useEffect, useId } from "react";
import FormLabel from "./FormLabel";
import InputError from "./InputError";

type Props = {
  label?: string;
  required?: boolean;
  error?: string;
  id?: string;
  withLockIcon?: boolean;
  message?: ReactNode;
  autoExpand?: boolean;
  setMaxHeight?: boolean;
  maxLength?: number;
};

const Textarea = forwardRef<ChakraTextareaProps & Props, "textarea">(
  (
    {
      label = "",
      required = false,
      error = "",
      id = "",
      withLockIcon = false,
      message = "",
      autoExpand = false,
      setMaxHeight = false,
      maxLength = undefined,
      ...props
    },
    ref,
  ) => {
    const boxId = useId();

    // useRefでやろうとしたが、初回レンダリング時のtextareaの高さを変えれなかったので
    // useEffectでquerySelectorを使用している
    useEffect(() => {
      if (!autoExpand) return;

      const textarea = document.querySelector<HTMLTextAreaElement>(
        `#textareabox-${CSS.escape(boxId)} textarea`,
      );
      if (textarea != null) {
        if (props.value !== "") {
          textarea.style.height = textarea.scrollHeight + "px";
        } else {
          // submit時の処理
          textarea.style.height = "auto";
        }
      }
    }, [autoExpand, boxId, props.value]);
    const length = props.value?.toString().length ?? 0;

    return (
      <Box width="100%" id={`textareabox-${boxId}`}>
        {label !== "" && (
          <FormLabel
            required={required}
            htmlFor={id}
            withLockIcon={withLockIcon}
          >
            {label}
          </FormLabel>
        )}
        <ChakraTextarea
          _placeholder={{ opacity: 1, color: "textGray" }}
          borderRadius="2px"
          border="1px solid #99A9B0"
          bgColor="#ffffff"
          errorBorderColor="#CD4429"
          id={id}
          py={3}
          maxH={setMaxHeight ? "5rem" : "auto"}
          isInvalid={error !== ""}
          _invalid={{ backgroundColor: "#FAE8E8", borderColor: "#CD4429" }}
          _disabled={{
            color: "#7E8C91",
            backgroundColor: "#F1F1F1",
            opacity: 1,
          }}
          {...props}
          ref={ref}
        />
        <Flex color="#6D787D" justifyContent="space-between" fontSize="xs">
          <Box as="span">{message}</Box>
          {maxLength != null && (
            <Box color={length > maxLength ? "caution" : undefined}>
              {length}/{maxLength}
            </Box>
          )}
        </Flex>
        {error !== "" && <InputError>{error}</InputError>}
      </Box>
    );
  },
);

export default Textarea;
